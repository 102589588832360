import Footer from "./Footer";
import Header from "./Header";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useEffect, useState, useRef } from "react";

export default function Layout({ children }) {
    const [childrenVH, setChildrenVH] = useState();
    const windowHeight = useWindowDimensions().height;
    const headerRef = useRef();
    const footerRef = useRef();

    useEffect(() => {
        var headerVH = (headerRef?.current?.clientHeight / windowHeight) * 100;
        var footerVH = (footerRef?.current?.clientHeight / windowHeight) * 100;
        setChildrenVH(100 - (headerVH + footerVH));
    }, [windowHeight, headerRef?.current?.clientHeight, footerRef?.current?.clientHeight])

    return (
        <>
            <main id="wrapper" className="bg-color--cloudy">
                <Header innerRef={headerRef} />
                <div style={{ minHeight: childrenVH + "vh" }}>
                    {children}
                </div>
                <Footer innerRef={footerRef} />
            </main>
        </>
    );
};
