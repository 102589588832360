import { createContext, useEffect, useState } from "react";
import { GetClientConfiguration } from "../api/client-configuration";
import Layout from "../components/Layout";
import Loader, { Types } from "../components/gdk/Loader";

export const ClientConfigurationContext = createContext();

export function ClientConfigurationContextProvider({ children }) {
    const [clientConfiguration, setClientConfiguration] = useState();

    useEffect(() => {
        GetClientConfiguration(response => {
            setClientConfiguration(response);
        });
    }, []);

    return (
        <ClientConfigurationContext.Provider value={clientConfiguration}>
            {!clientConfiguration &&
                <Loader type={Types.Section} />
            }
            {clientConfiguration?.masterSwitch === false &&
                <Layout>
                    <div className="container margin-top">
                        <h3>Maintenance</h3>
                        <p>
                            The system is down for maintenance. Please check again later.
                        </p>
                    </div>
                </Layout>
            }
            {clientConfiguration?.masterSwitch === true &&
                <>{children}</>
            }
        </ClientConfigurationContext.Provider>
    );
}