import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { GdkMultipleSelectBox } from "@gdk/multiple-select-box";
import $ from "jquery";
import { classes } from "../../util/style-utils";
import { updateAgencyProfile } from "../../api/agency-profile";

const AdditionalInformationForm = ({
    agencyProfile = {},
    setAgencyProfile = () => { },
    expandableForm
}) => {
    const [enableButtons, setEnableButtons] = useState(true);

    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = (values) => {
        if (!values.errors) {
            const associations = GetAssociationsValues();
            const businessRelationships = GetBusinessRelationshipsValues();
            const languages = GetLanguagesValues();

            const updatedAgencyProfile = {
                ...agencyProfile,
                associations: associations,
                maps: businessRelationships,
                businessAddress: {
                    ...agencyProfile.businessAddress,
                    city: values.businessCity
                },
                marketingIncentives: {
                    ...agencyProfile.marketingIncentives,
                    incentivesParticipation: values.incentivesParticipation,
                    incentivesContact: values.incentivesContact
                },
                languages: languages,
                website: values.website
            };

            setEnableButtons(false);

            updateAgencyProfile(updatedAgencyProfile,
                () => {
                    setAgencyProfile(updatedAgencyProfile);
                    expandableForm?.closeExpandedForm(document.getElementById("additional-information-form-li"));
                },
                () => {
                    setEnableButtons(true);
                });
        }
    }

    useEffect(() => { new GdkMultipleSelectBox({ content: "#associations-select" }); }, []);
    useEffect(() => { new GdkMultipleSelectBox({ content: "#business-relationships-select" }); }, []);
    useEffect(() => { new GdkMultipleSelectBox({ content: "#agency-languages-select" }); }, []);
    useEffect(() => { $("#associations-select").trigger("change") });
    useEffect(() => { $("#business-relationships-select").trigger("change") });
    useEffect(() => { $("#agency-languages-select").trigger("change") });

    const associationsOptions = AssociationsOptions();
    const businessRelationshipsOptions = BusinessRelationshipsOptions();
    const incentivesParticipationOptions = IncentivesParticipationOptions();
    const incentivesContactOptions = IncentivesContactOptions();
    const languageOptions = LanguageOptions();

    const incentivesContactWatch = watch("incentivesContact", String(agencyProfile?.marketingIncentives?.incentivesContact));
    const incentivesParticipationWatch = watch("incentivesParticipation", String(agencyProfile?.marketingIncentives?.incentivesParticipation));

    const onCancel = () => { reset(); };

    useEffect(() => {
        if (incentivesParticipationWatch === "true" && incentivesContactWatch === "OPTOUT") {
            setValue("incentivesContact", "");
        }
    }, [incentivesParticipationWatch, incentivesContactWatch]);

    useEffect(() => reset(), [agencyProfile, reset]);

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)} >
            <div className={classes("form-field", errors.associations && "form-field--error")}>
                <label htmlFor="associations-select" className="text">Associations</label>
                <div>
                    <select multiple id="associations-select" {...register("associations", { value: agencyProfile?.associations })} >
                        <option value=""></option>
                        {associationsOptions.map(option => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.associations &&
                    <span className="form-message">{errors.associations.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.businessRelationships && "form-field--error")}>
                <label htmlFor="business-relationships-select" className="text">Business Relationships</label>
                <div>
                    <select id="business-relationships-select" {...register("businessRelationships", { value: agencyProfile?.maps?.map((x) => x.name) })} >
                        <option value=""></option>
                        {businessRelationshipsOptions.map(option => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.businessRelationships &&
                    <span className="form-message">{errors.businessRelationships.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.businessCity && "form-field--error")}>
                <label htmlFor="business-city" className="text">Business City or County</label>
                <input id="business-city" type="text" size="30" {...register("businessCity", { value: agencyProfile.businessAddress?.city })} />
                {errors.businessCity &&
                    <span className="form-message">{errors.businessCity.message}</span>
                }
            </div>

            <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.incentivesParticipation && "form-field--error")}>
                <legend>Rewards Participation</legend>
                <div className="radio-button-wrapper col-2" >
                    {incentivesParticipationOptions?.map((option) => (
                        <div key={`${option.value}-incentives-participation-radio`}>
                            <input
                                disabled 
                                id={`${option.value}-participation-radio`}
                                type="radio"
                                value={option.value}
                                {...register("incentivesParticipation", { value: String(agencyProfile.marketingIncentives?.incentivesParticipation) })}
                            />
                            <label className="radio" htmlFor={`${option.value}-participation-radio`}><span>{option.label}</span></label>
                        </div>
                    ))}
                </div>
                {errors.incentivesParticipation &&
                    <span className="form-message">{errors.incentivesParticipation.message}</span>
                }
            </fieldset>

            {incentivesParticipationWatch === "true" && <div className={classes("form-field", errors.incentivesContact && "form-field--error")}>
                <label htmlFor="incentives-contact-select" className="text">Rewards Participation Level</label>
                <div className="select-box">
                    <select id="incentives-contact-select" disabled {...register("incentivesContact", { value: agencyProfile.marketingIncentives?.incentivesContact })}>
                        {incentivesContactOptions?.filter(option => incentivesParticipationWatch === "false" || option.value !== "OPTOUT")?.map(option => (
                            <option key={`${option.value}-incentives-contact-select`} value={option.value}>{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.incentivesContact &&
                    <span className="form-message">{errors.incentivesContact.message}</span>
                }
            </div>
            }

            <div className={classes("form-field", errors.languages && "form-field--error")}>
                <label htmlFor="agency-languages-select" className="text">Languages</label>
                <div>
                    <select multiple id="agency-languages-select" {...register("languages", { value: agencyProfile?.languages })} >
                        {languageOptions.map(option => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.languages &&
                    <span className="form-message">{errors.languages.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.website && "form-field--error")}>
                <label htmlFor="website" className="text">Website</label>
                <input id="website" type="text" size="30" {...register("website", { value: agencyProfile?.website })} />
                {errors.website &&
                    <span className="form-message">{errors.website.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <button
                        type="submit"
                        className="btn btn--primary btn--full-mobile btn--pull-right"
                        disabled={!enableButtons}
                    >
                        <span>Save</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                        onClick={() => onCancel()}
                        disabled={!enableButtons}
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>
    );
};

const urlReg = /^(https?:\/\/|https?:\/\/(www\.)|(www\.))[-a-zA-Z0-9@:%._\+~#=]+\.[a-zA-Z0-9()]+\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

const validationSchema = yup.object({
    associations: yup.array().test("at-least-one", "Please select at least one association.", () => GetAssociationsValues().length > 0),
    businessCity: yup.string().matches(/^[A-Za-z ]*$/, "City can only contain letters.").required("Please enter a city."),
    incentivesParticipation: yup.bool().required(),
    incentivesContact: yup.string().required("Required."),
    languages: yup.array().test("at-least-one", "Please select at least one language.", () => GetLanguagesValues().length > 0),
    website: yup.string().matches(urlReg, "Please enter a valid URL.").optional().nullable(),
});

const AssociationsOptions = () => [
    { value: "NOT", text: "None" },
    { value: "IIABA", text: "IIABA" },
    { value: "PIA", text: "National Association of Professional Insurance Agents" },
    { value: "SIA", text: "State Insurance Association" },
    { value: "OTHER", text: "Other" }
];

const BusinessRelationshipsOptions = () => [
    { value: "ACRI", text: "Acrisure" },
    { value: "AFFAM", text: "Affordable American" },
    { value: "AGTALLSRV", text: "Agents Alliance Services" },
    { value: "AGYCOLL", text: "Agency Collective" },
    { value: "AISI", text: "AIS Insurance" },
    { value: "ALERA", text: "Alera" },
    { value: "ALLEG", text: "Allegiance" },
    { value: "ALLIANT", text: "Alliant" },
    { value: "AON", text: "AON" },
    { value: "ARTJG", text: "Arthur J Gallagher" },
    { value: "ASSURPRT", text: "Assured Partners" },
    { value: "ATLAS", text: "Atlas Insurance Brokers" },
    { value: "BAINS", text: "Bainswest Inc" },
    { value: "BALDRP", text: "Baldwin Risk Partner" },
    { value: "BANDB", text: "Brown & Brown" },
    { value: "BRIGHT", text: "Brightway Ins" },
    { value: "BROADP", text: "Broadstreet Partners" },
    { value: "BXS", text: "BXS" },
    { value: "CACACS", text: "Cobbs Allen/CAC Specialty" },
    { value: "CCAGY", text: "Charles Crane Agency" },
    { value: "COMBAGTAM", text: "Combined Agt of American" },
    { value: "CONFIE", text: "Confie" },
    { value: "CONSOLIN", text: "Consolidated Insurance Nation" },
    { value: "COVERWALL", text: "Coverwallet" },
    { value: "CROSSINS", text: "Cross Insurance" },
    { value: "DANHEN", text: "The Daniel and Henry Company" },
    { value: "EPIC", text: "EPIC Insur Brokers and Consult" },
    { value: "ESTR", text: "Estrella" },
    { value: "FOUNDRISK", text: "Foundation Risk" },
    { value: "FRSTCHINS", text: "First Choice Ins" },
    { value: "GLOBGRN", text: "Global Green" },
    { value: "GOOSE", text: "Goosehead" },
    { value: "HEFFIB", text: "Heffernan Insurance Brokers" },
    { value: "HIGGIN", text: "Higginbotham" },
    { value: "HIGHSP", text: "High Street Partners" },
    { value: "HILB", text: "HILB Group Operations" },
    { value: "HORTON", text: "Horton Group" },
    { value: "HUB", text: "HUB" },
    { value: "HYLANT", text: "Hylant" },
    { value: "IMAFG", text: "IMA Financial Group" },
    { value: "IMS", text: "IMS" },
    { value: "INSOFFAM", text: "Insurance Office of American" },
    { value: "INSURICA", text: "INSURICA Inc" },
    { value: "INTEGRA", text: "Integra Financial Group" },
    { value: "ISU", text: "ISU" },
    { value: "KEYS", text: "Keystone" },
    { value: "LAWLEY", text: "Lawley LLC" },
    { value: "LEAVG", text: "Leavitt Group" },
    { value: "LIBCOMP", text: "The Liberty Company Ins Brokers" },
    { value: "LOCK", text: "Lockton" },
    { value: "MARSHBCP", text: "Marsh Berry Connect Platform" },
    { value: "MARSHMMA", text: "Marsh MMA" },
    { value: "MARSHUSA", text: "Marsh USA" },
    { value: "NFP", text: "NFP" },
    { value: "OAKINS", text: "Oakbridge Insurance" },
    { value: "OTHER", text: "Other" },
    { value: "PATGROW", text: "Patriot Growth" },
    { value: "PCF", text: "PCF" },
    { value: "PCIA", text: "PCIA Fin and Ins Services" },
    { value: "POLLY", text: "Polly" },
    { value: "PREMGI", text: "Premier Group Insurance" },
    { value: "RELII", text: "Relation Insurance Inc" },
    { value: "RENAG", text: "Renaissance Alliance Group" },
    { value: "RICHANDC", text: "Rich and Cartmill" },
    { value: "RRASSOC", text: "Robertson Ryan & Associates" },
    { value: "RSCIB", text: "RSC Insurance Brokerage" },
    { value: "SCOTTI", text: "Scott Insurance" },
    { value: "SIMPIOA", text: "Simply IOA" },
    { value: "SSB", text: "SSB Insurance" },
    { value: "STERSP", text: "Sterling Seacrest Pritchard" },
    { value: "SUNSTAR", text: "Sunstar Insurance Group" },
    { value: "TAG", text: "TAG Insurance Services" },
    { value: "TMAHONG", text: "The Mahoney Group" },
    { value: "TRUN", text: "True North" },
    { value: "TWFG", text: "TWFG" },
    { value: "UNIV", text: "UniVista" },
    { value: "USI", text: "USI" },
    { value: "WEINS", text: "We Insure Inc" },
    { value: "WESTIA", text: "Westwood Insurance Agency" },
    { value: "WILLIS", text: "Willis" },
    { value: "WOODSAW", text: "Woodruff Sawyer" },
    { value: "WRLDIA", text: "World Insurance Associates" }
]

const IncentivesParticipationOptions = () => [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

const IncentivesContactOptions = () => [
    { value: "AGY", text: "Agency Only" },
    { value: "PROD", text: "Producers Only" },
    { value: "BOTH", text: "Agency and Producers" },
    { value: "OPTOUT", text: "Opt-out" }
];

const LanguageOptions = () => [
    { value: "ARA", text: "Arabic" },
    { value: "CHN", text: "Chinese" },
    { value: "ENG", text: "English" },
    { value: "FRE", text: "French (Creole)" },
    { value: "KOR", text: "Korean" },
    { value: "SPA", text: "Spanish" },
    { value: "TAG", text: "Tagalog" },
    { value: "VNM", text: "Vietnamese" },
    { value: "OTHER", text: "Other" }
];

const GetAssociationsValues = () => $("select[name=associations]").select2("data").map(d => d.id);
const GetBusinessRelationshipsValues = () => $("select[name=businessRelationships]").select2("data").map(d => ({ name: d.id }));
const GetLanguagesValues = () => $("select[name=languages]").select2("data").map(d => d.id);

export default AdditionalInformationForm;
export {
    AssociationsOptions,
    IncentivesParticipationOptions,
    IncentivesContactOptions,
    LanguageOptions,
    BusinessRelationshipsOptions
};
