export const ReportState = Object.freeze({
    Overview: "Overview",
    Detailed: "Detailed View"
});

export const UserRoles = Object.freeze({
    Producer: "Producer",
    Principal: "IA Principal"
});

export const colors = ["#002F8F", "#1B62F8", "#02B8FE", "#05F4EA", "#6F11F2", "#D81BF1", "#F5196D", "#21E68D"]

export function formatNumber(num){
    return new Intl.NumberFormat('en-US', {
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
    }).format(num);
};

export function capitalizeName(name) { 
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(); 
}

export function switchViewLinkText(title){
    if (title === ReportState.Overview) {
        return 'Switch to Detailed View'
    } else if (title === ReportState.Detailed) {
        return 'Switch to Overview'
    }
}

export function getAvailableFilters(role){
    const producer = [
        {
            value: "RatedStates",
            label: "Rated State",
            optionsKey: "RatedState",
        },
        {
            value: "LineOfBusinesses",
            label: "LOB",
            optionsKey: "LineOfBusiness",
        },
        {
            value: "CommercialBusSegments",
            label: "Commercial Business Segment",
            optionsKey: "CommBusinessSegment"
        },
        {
            value: "PpaRiskSegments",
            label: "PPA Risk Segment",
            optionsKey: "PpaRiskSegment",
        }
    ]
    const principal = [
        ...producer,
        {
            value: "BranchIds",
            label: "Agency Branch",
            optionsKey: "AgencyBranchNames",
        },
        {
            value: "AgentIds",
            label: "Producer",
            optionsKey: "AgentProfiles",
        },
    ];

    return role === "IA Principal" ? principal : producer;
};