import PrimaryNavigation from "./PrimaryNavigation";

export default function Header({ innerRef }) {
    return (
        <>
            <a className="skip-to-content" href="#wrapper">Skip to main content</a>
            <header id="primary-header" role="banner" ref={innerRef}>
                <div className="header-logo">
                    <a href="/"><span className="icon-geico" aria-label="GEICO Home" /></a>
                </div>

                <div className="header-links">
                    <ul>
                        <li>
                            <a data-side-panel-trigger="menu" className="hamburger-menu" href="/">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span className="header-link">Menu</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </header>
            <PrimaryNavigation />
        </>
    );
};
