
import React from 'react';
import {
    VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip
} from 'victory';
import { colors } from '../ReportsHelper'
import { noDataMessage } from './ReportOverviewConstants';

export default function BarChart({data, slantLabels, dataField}) {
    return ( !data ? <div className='no-data'> {noDataMessage(dataField)} </div> :
        <VictoryChart domainPadding={{x: 40}} height={250} width={550}>
            <VictoryAxis
                tickFormat={(t) => t.length > 8 ? t.substring(0, 8) + '...' : t}
                style={{
                    tickLabels: {
                        fontSize: 9,
                        angle: slantLabels ? -60 : 0,
                        textAnchor: slantLabels ? 'end' : 'middle',
                    }
                }}
            />
            <VictoryAxis
                dependentAxis
                style={{
                    tickLabels: { fontSize: 9 },
                    grid: {
                        stroke: "#d9d9d9",
                        size: 5,
                    }
                }}
            />
            <VictoryBar
                data={data}
                x="name"
                y="count" 
                barRatio={.8}
                style={{
                    data: {
                        fill: ({ index }) => colors[index % colors.length],
                        },
                }} 
                labels={({ datum }) => `${datum.name}\nNew Business Count: ${datum.count}`}
                labelComponent={
                    <VictoryTooltip
                        constrainToVisibleArea
                        style={{ fill: "white", fontSize: 9 }}
                        flyoutStyle={{ fill: "#24232a",
                        stroke:
                            colors[0],
                        strokeWidth: 0.5,
                        }}
                    />
                }
            />
        </VictoryChart>
    )
}