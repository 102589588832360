import FileUploadState from './FileUploadState';

const FileUploadFileSelection = ({
    setErrorMessage,
    selectedFiles,
    setSelectedFiles = () => {},
    setFileUploadState = () => {},
}) => {

    const allowedFileExtensions = [".jpg", ".jpeg", ".rtif", ".tif", ".tiff", ".gif", ".png", ".mav", ".xls", ".xlsx", ".doc", ".docx", ".ppt", ".pptx", ".pdf"];
    const minFileSize = 1;
    const maxFileSize = 25600;
    const maxCombinedFileSize = 71680;
    const maxFileCount = 5;
    let combinedFileSize = 0;
    
    function handleSubmitAll(e) {
        setFileUploadState(FileUploadState.Submit);
    }

    function addFile(e) {

        if (e.target.files.length === 0 || isTotalFileCountInvalid(e.target.files, selectedFiles) 
            || isFileSizeInvalid(e.target.files) || isTotalFileSizeInvalid(e.target.files, selectedFiles))
        {
            return;
        }

        const selectedFileNames = selectedFiles.map(file => file.name);
        const filteredFiles = [...e.target.files]
            .filter(file => !selectedFileNames.includes(file.name) 
                && allowedFileExtensions.some((extension) => {
                    return file.name.endsWith(extension);
                }));

        setSelectedFiles([...selectedFiles, ...filteredFiles]);
        e.target.value = "";
        setErrorMessage(null);
    }

    function dropEvent(e) {
        e.preventDefault();

        const droppedFiles = [...e.dataTransfer.items]
            .filter(item => item.kind === "file")
            .map(item => item.getAsFile());

        const selectedFileNames = selectedFiles.map(file => file.name);
        const filteredFiles = droppedFiles
            .filter(file => !selectedFileNames.includes(file.name) 
                && allowedFileExtensions.some((extension) => {
                    return file.name.endsWith(extension);
                }));

        setSelectedFiles([...selectedFiles, ...filteredFiles]);
        e.target.value = "";
    }

    const removeFile = (selectedFile) => { 
        setSelectedFiles(selectedFiles.filter(file => file !== selectedFile)); 
    };

    function uploadCategoryChanged(file, selectBoxId) {
        var selectBox = document.getElementById(selectBoxId);
        file.selectedUploadCategory = selectBox.value;
    }

    function isFileSizeInvalid(files)
    {
        return Array.from(files).some(file => {
            const fileSize = file.size / Math.pow(1024, 1);

            if (fileSize < minFileSize) {
                setErrorMessage(file.name + " is less than the minimum size of 1KB." +
                    " Please try again using a larger file.");
                return true;
            }

            if (fileSize > maxFileSize) {
                setErrorMessage(file.name + " is greater than the maximum size of 25MB." +
                    " Please try again using a smaller file.");
                return true;
            }
            return false;
        })
    }

    function isTotalFileCountInvalid(files, uploadedFiles)
    {
        if (uploadedFiles.length === maxFileCount || uploadedFiles.length + files.length > maxFileCount)
            {
                setErrorMessage("You can only submit up to 5 files at a time.");
                return true;
            }
            return false;
    }

    function isTotalFileSizeInvalid(files, uploadedFiles)
    {
        for (let index = 0; index < uploadedFiles.length; ++index) {
            combinedFileSize += uploadedFiles[index].size;
        }

        for (let index = 0; index < files.length; ++index) {
            combinedFileSize += files[index].size;
        }

        if ((combinedFileSize / Math.pow(1024, 1)) > maxCombinedFileSize)
        {
            setErrorMessage("Your combined file sizes are greater than 70MB." +
                " Please adjust your file uploads to a smaller combined size.");
            return true;
        }
        return false;
    }

    return (
        <div 
            className={selectedFiles.length > 0 ? "drop doc-status-wrapper" : "drop drop-zone" } 
            onDragOver={(e) => e.preventDefault()} 
            multiple onDrop={dropEvent}
        >
            <div className="font--center">
                <span className="geico-icon icon-logout icon-upload"/>
                <p>Drop files here or 
                    <span> </span>
                    <input 
                        type="file" 
                        name="file" 
                        id="file"
                        className="hide-file" 
                        multiple onChange={addFile} 
                        accept= {allowedFileExtensions}
                    />
                    <label htmlFor="file" className="drop-link">select files</label>
                </p>
            </div>
            { selectedFiles.length > 0 && 
                <div>
                    <div className="doc-upload container-padding">
                        <div className="doc-upload-header">
                            <h3>Selected Files</h3>
                            <button 
                                type="button" 
                                className="btn btn--primary btn--pull-right hidden-sm" 
                                onClick={handleSubmitAll}>
                                <span>Submit All Files</span>
                            </button>
                        </div>
                        { selectedFiles.map((file) => (
                            <div key={`${file.name}-selected-file-container`}>
                                <span className="stroke-separator"/>
                                <div className="doc-upload-files">
                                    <span className="img-icon png-image-icon hidden-sm hidden-md"></span>
                                    <div>{file?.name}</div>
                                    <a>
                                        <span 
                                            className="geico-icon geico-icon--actionable geico-icon--small icon-trash" 
                                            onClick={() => removeFile(file, `${file.name}-selected-file-container`)}
                                        />
                                    </a>
                                </div>
                                <div className="doc-upload-select">
                                    <div>
                                        <div className="form-field ">
                                            <label 
                                                htmlFor="upload-category" 
                                                className="text ">
                                                Select a category for this file:
                                            </label>
                                            <div className="select-box">
                                                <select 
                                                    id={`${file.name}-upload-category-select-box`} 
                                                    name="upload-category" 
                                                    onChange={() => {uploadCategoryChanged(file, `${file.name}-upload-category-select-box`)}}
                                                >
                                                    <option value="Errors and Omissions">Errors and Omissions</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default FileUploadFileSelection;
