import { useNavigate } from "react-router-dom";

export default function ReportsTabContent() {
    const navigate = useNavigate();

    return (
        <div className="col-sm-12 padding-top">
            <div className="col-md-10">
                <div>Your monthly production data can be accessed by clicking Reports button below</div>
                <br/>
                <button type="button" className="btn btn--primary" onClick={() => navigate('/reports')}>
                    <span>Reports</span>
                </button>
            </div>
        </div>
    );
};
