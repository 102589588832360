import React from 'react';
import '../Reports.css';
import { formatNumber, colors } from '../ReportsHelper';
import CardHeader from './CardHeader';

export default function AggregateCard({ number, title, tooltipContent }) {

    const formattedNumber = formatNumber(title.includes("Closure") ? number * 100 : number);

    return (
        <div className="card">
            <CardHeader title={title} tooltipContent={tooltipContent} />
            <div className="card-number" style={{color:colors[0]}}>{title.includes("Closure") ? `${formattedNumber}%` : formattedNumber}</div>
        </div>
    );
}