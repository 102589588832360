import { handleErrors } from "../util/api-utils";

export function searchAgencies(request, successCallback) {
    fetch("api/Search/AgencySearch", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function searchAgents(request, successCallback) {
    fetch("api/Search/AgentSearch", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function salesLeaderAgentSearch(request, successCallback) {
    fetch("api/Search/SalesLeaderAgentSearch", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};
