import { useState } from "react";
import SegmentedControl from "../gdk/SegmentedControl/SegmentedControl"
import AgentSearch from "../AgentSearch/AgentSearch";
import AgencySearch from "../AgencySearch/AgencySearch";

const ProfileSearch = () => {
    const [activeSegment, setActiveSegment] = useState({ innerText: "Agent Search" });

    return (
        <>
            <SegmentedControl
                segment1="Agent Search"
                segment2="Agency Search"
                setActiveSegment={setActiveSegment}
            />
            {activeSegment?.innerText === "Agent Search" &&
                <AgentSearch />
            }
            {activeSegment?.innerText === "Agency Search" &&
                <AgencySearch />
            }
        </>
    );
};

export default ProfileSearch;
