import { useState, useEffect } from "react";
import useBranchOptions from "../../hooks/useBranchOptions";
import useContactMethodOptions from "../../hooks/useContactMethodOptions";
import useContentPreferenceOptions from "../../hooks/useContentPreferencesOptions";
import useLanguageOptions from "../../hooks/useLanguageOptions";
import ExpandableFormList from "../gdk/ExpandableForm/ExpandableFormList";
import ExpandableFormListItem from "../gdk/ExpandableForm/ExpandableFormListItem";
import AgentProfileForm from "./AgentProfileForm";
import { v4 } from "uuid";

export default function AgentProfile({
    agentProfile,
    setAgentProfile
}) {
    const [expandableForm, setExpandableForm] = useState();
    const branchOptions = useBranchOptions(agentProfile?.agency?.agencyId);
    const languageOptions = useLanguageOptions();
    const contactMethodOptions = useContactMethodOptions();
    const contentPreferencesOptions = useContentPreferenceOptions();
    const expandableFormId = `li-${v4()}`;

    return (
        <>
            <ExpandableFormList setExpandableForm={setExpandableForm}>
                <ExpandableFormListItem
                    id={expandableFormId}
                    headline={AgentFullName(agentProfile)}
                    subtext={agentProfile?.jobProfile}
                    editable={true}
                    summaryItems={AgentProfileSummary(agentProfile, branchOptions, languageOptions, contactMethodOptions, contentPreferencesOptions)}
                    form={<AgentProfileForm
                        agentProfile={agentProfile}
                        setAgentProfile={setAgentProfile}
                        expandableForm={expandableForm}
                        expandableFormId={expandableFormId}
                    />}
                />
            </ExpandableFormList>
        </>
    );
};

const AgentProfileSummary = (agentProfile, branchOptions, languageOptions, contactMethodOptions, contentPreferencesOptions) => [
    {
        name: "Agent ID",
        value: agentProfile?.id
    },
    {
        name: "Agency Branch",
        value: branchOptions?.find(o => o.value === agentProfile?.agency?.branchId)?.text
    },
    {
        name: "Business Phone",
        value: agentProfile?.phones?.businessExtension ?
            `${agentProfile?.phones?.business} ${agentProfile?.phones?.businessExtension}`
            : agentProfile?.phones?.business
    },
    {
        name: "Mobile Phone",
        value: agentProfile?.phones?.cell
    },
    {
        name: "Home Phone",
        value: agentProfile?.phones?.resident
    },
    {
        name: "Fax Number",
        value: agentProfile?.phones?.fax
    },
    {
        name: "Business Email",
        value: agentProfile?.businessEmail
    },
    {
        name: "Email",
        value: agentProfile?.email
    },
    {
        name: "Business Address",
        value: FormatAddress(agentProfile?.addresses?.businessAddress)
    },
    {
        name: "Mailing Address",
        value: FormatAddress(agentProfile?.addresses?.mailingAddress)
    },
    {
        name: "Residential Address",
        value: FormatAddress(agentProfile?.addresses?.residentAddress)
    },
    {
        name: "Languages",
        value: languageOptions?.filter(o => agentProfile?.languages?.includes(o.value)).map(o => o.text).join(", ")
    },
    {
        name: "National Producer Number",
        value: agentProfile?.nationalProducerNumber
    },
    {
        name: "Lines of Business",
        value: agentProfile?.lobCanSell?.join(", ")
    },
    {
        name: "License Number",
        value: agentProfile?.license?.licenseNumber
    },
    {
        name: "License State",
        value: agentProfile?.license?.licenseState
    },
    {
        name: "Preferred Contact Methods",
        value: contactMethodOptions?.filter(o => agentProfile?.contactMethods?.includes(o.value)).map(o => o.text).join(", ")
    },
    {
        name: "Content Preferences",
        value: contentPreferencesOptions?.filter(o => agentProfile?.contentPreferences?.includes(o.value)).map(o => o.text).join(", ")
    }
];

export const AgentFullName = (agentProfile) => [
    agentProfile?.firstName,
    agentProfile?.middleName,
    agentProfile?.lastName,
    agentProfile?.suffix
].join(" ");

const FormatAddress = (address) => [
    address?.street1,
    address?.street2,
    address?.city && address?.city + ",",
    address?.state,
    address?.zipCode
].join(" ");
