/* eslint-disable jsx-a11y/anchor-is-valid */

import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { searchAgencies } from '../../api/search';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { GdkPillFilter } from '@gdk/pill-filter';
import { classes } from '../../util/style-utils';

const defaultValues = {
    agencyId: "",
    agencyName: "",
    lastName: "",
    firstName: "",
    state: "",
    zipCode: ""
};

const validationSchema = yup.object({
    agencyId: yup.string().matches(/^[Gg]\d{0,5}$/, { message: "Invalid Agency Id, ex: G12345", excludeEmptyString: true }),
    agencyName: yup.string().max(100, "Agency Name is too long."),
    principalLastName: yup.string(),
    principalFirstName: yup.string(),
    state: yup.string().max(2, "State must be two letters.").matches(/[a-zA-Z]+/g, { message: "State must contain only letters.", excludeEmptyString: true }),
    zipCode: yup.string().matches(/^[\d]{0,5}$/, 'Please enter a valid ZIP code.'),
}).optional();

export default function AgencySearch() {
    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });

    const [agencies, setAgencies] = useState();

    const watchAll = watch();
    const watchLastName = watch("principalLastName");

    const onSubmit = data => {
        const request = {
            ...data,
            agencyId: data.agencyId.toUpperCase(),
            state: data.state.toUpperCase()
        };
        
        searchAgencies(request, response => setAgencies(response));
    };

    const clearInput = () => {
        setAgencies();
        reset();
    };

    const buttonStyle = {
        "marginBottom": "0",
        "marginTop": "3rem"
    };

    const gdkPillFilterRef = useRef();

    useEffect(() => {
        gdkPillFilterRef.current = new GdkPillFilter({
            content: "#agency-search-pill-filters"
        });
    }, []);

    return (
        <form className="form agency-search-form" onSubmit={handleSubmit(onSubmit)}>
            <style>
                {`
                    .agency-search-form .uppercase-input {
                        text-transform: uppercase
                    }

                    .agency-search-form .uppercase-input::placeholder {
                        text-transform: initial
                    }
                `}
            </style>
            <div className="container agency-search-fields">
                <h3 className='mb-5'>Find an agency profile</h3>
                <div className="row">
                    <div className="col-sm-3">
                        <div className={classes("form-field", errors.agencyId && "form-field--error")}>
                            <label htmlFor="agencyId" className="text">Agency ID</label>
                            <input id="agencyId" className='uppercase-input' {...register('agencyId')} type="text" size="15" placeholder="G Code" maxLength="6" />
                            {errors.agencyId && (
                                <span className="form-message">{errors.agencyId.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className={classes("form-field", errors.agencyName && "form-field--error")}>
                            <label htmlFor="agencyName" className="text">Agency Name</label>
                            <input id="agencyName" {...register('agencyName')} type="text" size="30" placeholder="Enter agency name" />
                            {errors.agencyName && (
                                <span className="form-message">{errors.agencyName.message}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-5">
                        <div className={classes("form-field", errors.lastName && "form-field--error")}>
                            <label htmlFor="principalLastName" className="text">Principal Agent</label>
                            <input id="principalLastName" {...register('principalLastName')} type="text" size="30" placeholder="Last Name" />
                            {errors.lastName && errors.lastName.ref.value !== "" && (
                                <span className="form-message">{errors.lastName.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className={classes("form-field", errors.firstName && "form-field--error")}>
                            <label htmlFor="principalFirstName" className="text">&nbsp;</label>
                            <input
                                id="principalFirstName"
                                {...register('principalFirstName')}
                                type="text" size="30"
                                placeholder="First Name"
                                title='Enter a last name before entering a first name.'
                                disabled={!watchLastName}
                            />
                            {errors.firstName && (
                                <span className="form-message">{errors.firstName.message}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2">
                        <div className={classes("form-field", errors.state && "form-field--error")}>
                            <label htmlFor="state" className="text">State</label>
                            <input
                                id="state"
                                className='uppercase-input'
                                {...register('state')}
                                type="text"
                                size="5"
                                placeholder="ST"
                                title='Enter another value before state.'
                                disabled={Object.values(watchAll).every(element => !element)}
                            />
                            {errors.state && (
                                <span className="form-message">{errors.state.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className={classes("form-field", errors.zipCode && "form-field--error")}>
                            <label htmlFor="zipCode" className="text">ZIP Code</label>
                            <input id="zipCode" {...register('zipCode')} type="text" size="5" placeholder="ZIP" />
                            {errors.zipCode && (
                                <span className="form-message">{errors.zipCode.message}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-7" style={{ "marginBottom": "3.5rem" }}>
                        <label className="text">Include:</label>
                        <div id="agency-search-pill-filters" className="pill-filter">
                            <button id="agencies-pill" type="button" className="btn btn--pill-filter multi-choice" style={{ "margin": "1rem" }}>
                                <span>Agencies</span>
                            </button>
                            <button id="branches-pill" type="button" className="btn btn--pill-filter multi-choice" style={{ "margin": "1rem" }}>
                                <span>Branches</span>
                            </button>
                            <button id="maps-pill" type="button" className="btn btn--pill-filter multi-choice" style={{ "margin": "1rem" }}>
                                <span>MAPs</span>
                            </button>
                        </div>
                    </div>
                    <div className='col-sm-5 align-bottom'>
                        <button
                            type="submit"
                            className="btn btn--primary btn--full-mobile btn--pull-right"
                            style={buttonStyle}
                            disabled={Object.values(watchAll).every(element => !element)}>
                            <span>
                                Search
                            </span>
                        </button>
                        <button
                            id="agent-search-clear-button"
                            type="button"
                            className="btn btn--secondary btn--full-mobile btn--pull-right"
                            style={buttonStyle}
                            onClick={(() => { clearInput() })}
                            disabled={Object.values(watchAll).every(element => !element)}
                        >
                            <span>
                                Clear
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <span className="stroke-separator"></span>
            <div className="container">
                {agencies && agencies.length === 0 &&
                    <p>
                        No results.
                    </p>
                }
                {agencies && agencies.length !== 0 &&
                    <div className="data-table">
                        <table id="AgencySearch_Table" className="table">
                            <thead>
                                <tr key="headers">
                                    <th data-sort-method='none'>Agency ID</th>
                                    <th className="sort-header"><a>Agency Name</a></th>
                                    <th className="sort-header"><a>Type</a></th>
                                    <th className="sort-header"><a>Business Address</a></th>
                                    <th className="sort-header"><a>Principal</a></th>
                                    <th className="sort-header"><a>Parent Agency Id</a></th>
                                </tr>
                            </thead>
                            <tbody>
                                {agencies.map((agency, index) =>
                                    <tr key={index + "row"}>
                                        <td><Link to={`/profiles`} state={{ agencyId: agency?.agencyId }}>{agency?.agencyId}</Link></td>
                                        <td>{agency?.name}</td>
                                        <td>
                                            {agency?.isBranch ? "Branch"
                                                : agency?.isMAP ? "Map"
                                                    : "Agency"}
                                        </td>
                                        <td>
                                            {agency?.businessAddress &&
                                                <>
                                                    <div>{agency?.businessAddress?.street1}</div>
                                                    <div>{agency?.businessAddress?.city + ", " + agency?.businessAddress?.state}</div>
                                                    <div>{agency?.businessAddress?.zipCode}</div>
                                                </>
                                            }
                                        </td>
                                        <td>{agency?.principal?.name?.firstName + " " + agency?.principal?.name?.lastName}</td>
                                        <td>{agency?.parentAgencyId}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </form>
    );
};
