export const backgroundColors = ["#ffffff", "#f2f2f2", "#e6e6e6", "#d9d9d9", "#cccccc"];

export const scorecardHeaders = {
    groups: ["PPA/BA/CON", "Truck", "All"],
    metrics: ["Opportunities", "NBUS", "Closure", "Premium"]
}

export const scorecardDataFields = [
    "Ppa_bba_con_opportunities",
    "Ppa_bba_con_new_business",
    "Ppa_bba_con_closure",
    "Ppa_bba_con_bound_premium",
    "Truck_opportunities",
    "Truck_new_business",
    "Truck_closure",
    "Truck_bound_premium",
    "All_opportunities",
    "All_new_business",
    "All_closure",
    "All_bound_premium"
]

export function getScorecardOrderedGroupingKeys(role) {
    return role === "IA Principal"
        ? ["MasterAgencySourceOfBusinessAndName", "AgentSourceOfBusinessAndName", "ReportingDate", "ProductSubGroup"]
        : ["ReportingDate", "ProductSubGroup"];
}