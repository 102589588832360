import { useEffect, useState } from "react";

export default function useYesNoOptions() {
    const [yesNoOptions, setYesNoOptions] = useState([]);

    useEffect(() => {
        setYesNoOptions([
            { value: true, label: "Yes" },
            { value: false, label: "No" }
        ]);
    }, []);

    return yesNoOptions;
}