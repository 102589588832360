import { GdkLoader } from "@gdk/loader";
import { useEffect } from "react";
import { v4 } from "uuid";

export const Types = {
    Inline: "inline",
    Section: "section"
};

export default function Loader({ type = Types.Inline, autoShow = true }) {
    const id = `loader-${v4()}`;

    useEffect(() => {
        new GdkLoader({
            "content": `#${id}`,
            "type": type,
            "autoShow": autoShow
        })
    }, []);

    if (type === Types.Inline) {
        return <>
            <div id={id} className="loader"></div>
        </>;
    }

    return <>
        <div id={id} className="loader-container">
            <div className="loader-content">
                <div className="loader loader--animated" aria-label="Please wait">
                    <div className="animated-loader-copy">
                        Please wait
                        <div className="ellipses"><span className="ellipses-1">.</span><span className="ellipses-2">.</span><span className="ellipses-3">.</span></div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}