import { useState } from "react";

export default function useSuffixOptions() {
    const [suffixOptions] = useState([
        { value: "", label: "None" },
        { value: "jr", label: "Jr." },
        { value: "sr", label: "Sr." },
        { value: "ii", label: "II" },
        { value: "iii", label: "III" }
    ]);

    return suffixOptions;
}