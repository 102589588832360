import { useEffect, useRef, useState } from "react";
import ExpandableFormList from "../gdk/ExpandableForm/ExpandableFormList";
import ExpandableFormListItem from "../gdk/ExpandableForm/ExpandableFormListItem";
import AgencyBranchesForm from "./AgencyBranchesForm";
import { GdkMultipleSelectBox } from "@gdk/multiple-select-box";
import $ from 'jquery';
import { searchAgencies } from "../../api/search";

export default function AgencyBranches({
    parentAgencyId,
    selectedBranchId
}) {
    const [agencyBranches, setAgencyBranches] = useState([]);
    const [selectedAgencyBranch, setSelectedAgencyBranch] = useState({});
    const [selectedAgencyBranchId, setSelectedAgencyBranchId] = useState(selectedBranchId);
    const [expandableForm, setExpandableForm] = useState();

    const agencyBranchSelectBoxRef = useRef();

    const onAgencyBranchSelectBoxChange = () => {
        setSelectedAgencyBranchId(agencyBranchSelectBoxRef.current.value);
    };

    const setAgencyBranch = (agencyBranch) => {
        setSelectedAgencyBranch(agencyBranch);

        if (agencyBranches.some(p => p)) {
            setAgencyBranches(agencyBranches.map(b => b.agencyId === agencyBranch.agencyId ? agencyBranch : b));
        }
    };

    useEffect(() => {
        new GdkMultipleSelectBox({
            "content": "#agency-branches-select-box"
        });

        $("#agency-branches-select-box").on("change", onAgencyBranchSelectBoxChange);
    }, []);

    useEffect(() => {
        searchAgencies({ parentAgencyId: parentAgencyId, isBranch: true }, response => setAgencyBranches(response));
    }, []);

    useEffect(() => {
        setSelectedAgencyBranch(agencyBranches.find(b => b.agencyId === selectedAgencyBranchId) ?? {});
    }, [selectedAgencyBranchId, agencyBranches]);

    useEffect(() => {
        if (selectedBranchId) {
            $("#agency-branches-select-box").val(selectedBranchId).trigger("change");
        }

        setSelectedAgencyBranchId(agencyBranchSelectBoxRef.current.value);
    }, [agencyBranches]);

    const agencyBranchesSummary = (selectedAgencyBranch) => [
        { name: "Number of Centers under same EIN", value: selectedAgencyBranch ? agencyBranches?.length : "" },
        { name: "Branch Name", value: selectedAgencyBranch?.name },
        { name: "Branch Address", value: AddressString(selectedAgencyBranch?.businessAddress) },
        { name: "Contact", value: `${selectedAgencyBranch?.branchContact?.firstName ?? ""} ${selectedAgencyBranch?.branchContact?.lastName ?? ""}` },
        { name: "Role", value: selectedAgencyBranch?.branchContact?.role },
        { name: "Email", value: selectedAgencyBranch?.branchContact?.email }
    ];

    return <>
        {(agencyBranches == null || agencyBranches?.length === 0) &&
            <div id="alert-low" className="alert">
                <div className="alert--low-importance">
                    <span className="icon-alert" />
                    <ul className="alert-list">
                        <li className="alert-content">
                            <div className="h4">No Branches Available</div>
                            <p>
                                Your agency does not have any branches.
                            </p>
                            {/* 
                                TODO: Uncomment the following line and update link to more information
                                <a href="/alert">More Information</a> 
                            */}
                        </li>
                    </ul>
                </div>
            </div>
        }
        <div hidden={!agencyBranches?.length > 0}>
            <div className="form-field">
                <label htmlFor="agency-branches-select-box" className="text">Select an Agency Branch</label>
                <div>
                    <select id="agency-branches-select-box" ref={agencyBranchSelectBoxRef} onChange={onAgencyBranchSelectBoxChange} disabled={!agencyBranches}>
                        {agencyBranches
                            ?.sort((a, b) => a.name?.localeCompare(b.name))
                            ?.map(branch =>
                                <option value={branch?.agencyId} key={branch?.agencyId}>
                                    {`${branch?.agencyId} - ${branch?.name} `}
                                </option>
                            )
                        }
                    </select>
                </div>
            </div>
            <ExpandableFormList setExpandableForm={setExpandableForm}>
                <ExpandableFormListItem
                    id="agency-branch-form-li"
                    headline={selectedAgencyBranch?.name}
                    subHeadline={selectedAgencyBranch?.agencyId}
                    subtext={selectedAgencyBranch?.businessAddress?.street1}
                    editable={true}
                    summaryItems={agencyBranchesSummary(selectedAgencyBranch)}
                    form={<AgencyBranchesForm
                        agencyBranch={selectedAgencyBranch}
                        setAgencyBranch={setAgencyBranch}
                        expandableForm={expandableForm}
                    />}
                />
            </ExpandableFormList>
        </div>
    </>;
};

const AddressString = (a) => a ? `${a.street1}, ${a.city}, ${a.state}, ${a.zipCode}` : "";