/* eslint-disable jsx-a11y/anchor-is-valid */

import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { searchAgents } from '../../api/search';
import { Link } from 'react-router-dom';
import { useContext, useRef, useState } from 'react';
import { useMask } from '@react-input/mask';
import { classes } from '../../util/style-utils';
import { UserContext } from '../../context/UserContextProvider';

const validationSchema = yup.object({
    agentId: yup.string().matches(/^[iI]\d{0,6}$/, { message: "Invalid Agent Id, ex: I123456", excludeEmptyString: true }),
    npn: yup.string().max(50, "NPN is too long."),
    agencyId: yup.string().matches(/^[gG]\d{0,5}$/, { message: "Invalid Agency Id, ex: G12345", excludeEmptyString: true }),
    lastName: yup.string(),
    firstName: yup.string(),
    email: yup.string().email("Please enter a valid email."),
    state: yup.string().max(2, "State must be two letters.").matches(/[a-zA-Z]+/g, { message: "State must contain only letters.", excludeEmptyString: true }),
    agentPhone: yup.string().matches(/^[\d()-/ ]{0,14}$/, 'Please enter a valid phone number.'),
}).optional();

export default function AgentSearch() {
    const user = useContext(UserContext);

    const { register, reset, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            agentId: "",
            npn: "",
            agencyId: user.agencyId,
            lastName: "",
            firstName: "",
            email: "",
            state: "",
            agentPhone: ""
        }
    });

    const [agents, setAgents] = useState();

    const watchAll = watch();
    const watchLastName = watch("lastName");
    const watchAgencyId = watch("agencyId");

    const { ref: registerAgentPhoneRef, ...registerAgentPhone } = register("agentPhone");
    const maskAgentPhoneRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ }, onMask: (event) => { agentPhoneUnmasked.current = event.detail?.input; } });
    const agentPhoneUnmasked = useRef("");

    const onSubmit = data => {
        const request = {
            ...data,
            agentPhone: agentPhoneUnmasked.current,
            agentId: data.agentId.toUpperCase(),
            agencyId: data.agencyId.toUpperCase(),
            state: data.state.toUpperCase()
        };

        searchAgents(request, response => setAgents(response));
    };

    const clearInput = () => {
        setAgents();
        reset();
    };

    const buttonStyle = {
        "marginBottom": "0",
        "marginTop": "3rem"
    };

    return (
        <form className="form agent-search-form" onSubmit={handleSubmit(onSubmit)}>
            <style>
                {`
                    .agent-search-form .uppercase-input {
                        text-transform: uppercase
                    }

                    .agent-search-form .uppercase-input::placeholder {
                        text-transform: initial
                    }
                `}
            </style>
            <div className="container agent-search-fields">
                <h3 className="mb-5">Find an agent profile</h3>
                <div className="row">
                    <div className="col-sm-3">
                        <div
                            className={classes(
                                "form-field",
                                errors.agentId && "form-field--error"
                            )}
                        >
                            <label htmlFor="agentId" className="text">
                                Agent ID
                            </label>
                            <input
                                id="agentId"
                                className='uppercase-input'
                                type="text"
                                size="15"
                                placeholder="I Code"
                                {...register("agentId")}
                                maxLength="7"
                            />
                            {errors.agentId && (
                                <span className="form-message">{errors.agentId.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div
                            className={classes(
                                "form-field",
                                errors.npn && "form-field--error"
                            )}
                        >
                            <label htmlFor="npn" className="text">
                                National Producer Number
                            </label>
                            <input
                                id="npn"
                                className='uppercase-input'
                                type="text"
                                size="30"
                                placeholder="NPN"
                                {...register("npn")}
                            />
                            {errors.npn && (
                                <span className="form-message">{errors.npn.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className={classes("form-field")}>
                            <label htmlFor="agencyId" className="text">
                                Agency ID
                            </label>
                            <input
                                id="agencyId"
                                type="text"
                                size="15"
                                placeholder="G Code"
                                {...register("agencyId")}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div
                            className={classes(
                                "form-field",
                                errors.lastName && "form-field--error"
                            )}
                        >
                            <label htmlFor="lastName" className="text">
                                Last Name
                            </label>
                            <input
                                id="lastName"
                                type="text"
                                size="30"
                                placeholder="Last Name"
                                {...register("lastName")}
                            />
                            {errors.lastName && (
                                <span className="form-message">
                                    {errors.lastName.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div
                            className={classes(
                                "form-field",
                                errors.firstName && "form-field--error"
                            )}
                        >
                            <label htmlFor="firstName" className="text">
                                First Name
                            </label>
                            <input
                                id="firstName"
                                type="text"
                                size="30"
                                placeholder="First Name"
                                title="Enter a last name before entering a first name."
                                disabled={!watchLastName}
                                {...register("firstName")}
                            />
                            {errors.firstName && (
                                <span className="form-message">
                                    {errors.firstName.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div
                            className={classes(
                                "form-field",
                                errors.email && "form-field--error"
                            )}
                        >
                            <label htmlFor="email" className="text">
                                Email
                            </label>
                            <input
                                id="email"
                                type="text"
                                size="30"
                                placeholder="Email"
                                {...register("email")}
                            />
                            {errors.email && (
                                <span className="form-message">{errors.email.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div
                            className={classes(
                                "form-field",
                                errors.state && "form-field--error"
                            )}
                        >
                            <label htmlFor="state" className="text">
                                State
                            </label>
                            <input
                                id="state"
                                className='uppercase-input'
                                type="text"
                                size="5"
                                placeholder="ST"
                                title="Enter another value to before state."
                                disabled={Object.values(watchAll).every(
                                    (element) => !element
                                )}
                                {...register("state")}
                            />
                            {errors.state && (
                                <span className="form-message">{errors.state.message}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div
                            className={classes(
                                "form-field",
                                errors.agentPhone && "form-field--error"
                            )}
                        >
                            <label htmlFor="agentPhone" className="text">
                                Phone
                            </label>
                            <input
                                id="agentPhone"
                                type="text"
                                size="15"
                                placeholder="(___) ___-____"
                                ref={(e) => {
                                    registerAgentPhoneRef(e);
                                    maskAgentPhoneRef.current = e;
                                }}
                                {...registerAgentPhone}
                            />
                            {errors.agentPhone && (
                                <span className="form-message">
                                    {errors.agentPhone.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6 align-bottom">
                        <button
                            type="submit"
                            className="btn btn--primary btn--full-mobile btn--pull-right"
                            style={buttonStyle}
                        >
                            <span>Search</span>
                        </button>
                        <button
                            id="agent-search-clear-button"
                            type="button"
                            className="btn btn--secondary btn--full-mobile btn--pull-right"
                            style={buttonStyle}
                            onClick={() => {
                                clearInput();
                            }}
                            disabled={Object.values(watchAll).every((element) => !element || element === watchAgencyId)}
                        >
                            <span>Clear</span>
                        </button>
                    </div>
                </div>
            </div>
            <span className="stroke-separator"></span>
            <div className="container">
                {agents && agents.length === 0 && <p>No results.</p>}
                {agents && agents.length !== 0 && (
                    <div className="data-table">
                        <table id="AgentSearch_Table" className="table">
                            <thead>
                                <tr key="headers">
                                    <th data-sort-method="none">Agent ID</th>
                                    <th className="sort-header">
                                        <a>Name</a>
                                    </th>
                                    <th className="sort-header">
                                        <a>NPN</a>
                                    </th>
                                    <th className="sort-header">
                                        <a>Email</a>
                                    </th>
                                    <th className="sort-header">
                                        <a>Agency ID</a>
                                    </th>
                                    <th className="sort-header">
                                        <a>Branch ID</a>
                                    </th>
                                    <th data-sort-method="none">Phone Numbers</th>
                                    <th data-sort-method="none">Address States</th>
                                </tr>
                            </thead>
                            <tbody>
                                {agents.map((agent, index) => (
                                    <tr key={index + "row"}>
                                        <td key={index + "id"}>
                                            <Link to={`/profiles`} state={{ agentId: agent?.id }}>
                                                {agent?.id}
                                            </Link>
                                        </td>
                                        <td key={index + "name"}>
                                            {agent?.lastName + ", " + agent?.firstName}
                                        </td>
                                        <td key={index + "npn"}>
                                            {agent?.nationalProducerNumber}
                                        </td>
                                        <td key={index + "email"}>{agent?.email}</td>
                                        <td key={index + "agencyid"}>
                                            <Link
                                                to={`/profiles`}
                                                state={{ agencyId: agent?.agency?.agencyId }}
                                            >
                                                {agent?.agency?.agencyId}
                                            </Link>
                                        </td>
                                        <td key={index + "branchid"}>
                                            {agent?.agency?.branchId}
                                        </td>
                                        <td key={index + "phone"}>
                                            {agent?.phones?.business === agent?.phones?.cell ? (
                                                agent?.phones?.business
                                            ) : (
                                                <>
                                                    {agent?.phones?.business && (
                                                        <div>{agent?.phones?.business}</div>
                                                    )}
                                                    {agent?.phones?.cell && (
                                                        <div>{agent?.phones?.cell}</div>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td key={index + "state"}>
                                            {agent?.addresses?.businessAddress?.state ===
                                                agent?.addresses?.residentAddress?.state ? (
                                                agent?.addresses?.businessAddress?.state
                                            ) : (
                                                <>
                                                    {agent?.addresses?.businessAddress?.state && (
                                                        <div>
                                                            {agent?.addresses?.businessAddress?.state}
                                                        </div>
                                                    )}
                                                    {agent?.addresses?.residentAddress?.state && (
                                                        <div>
                                                            {agent?.addresses?.residentAddress?.state}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </form>
    );
};
