import { GdkTooltip } from "@gdk/tooltip";
import { useEffect, useRef } from "react"
import { v4 } from "uuid";

export default function TooltipLabel({
    htmlFor,
    label,
    content,
    toolTipOpen = () => { },
    toolTipClose = () => { }
}) {
    const labelId = useRef(`label-${v4()}`);
    const triggerId = useRef(`trigger-${v4()}`);
    const tooltipId = useRef(`tooltip-${v4()}`);

    useEffect(() => {
        new GdkTooltip({
            content: `#${triggerId.current}`,
            toolTipOpen: toolTipOpen,
            toolTipClose: toolTipClose
        });
    }, []);

    return <>
        <div>
            <label id={labelId.current} className="text label--inline" htmlFor={htmlFor}>{label}</label>
            <div className="tooltip-container">
                <a
                    href="#"
                    id={triggerId.current}
                    className="tooltip-trigger"
                    data-tooltip-view={tooltipId.current}
                    aria-labelledby={labelId.current}
                >
                    <span className="icon-question-mark"></span>
                </a>
            </div>
        </div>
        <div id={tooltipId.current} className="tooltip">
            <div tabIndex="0">{content}</div>
            <button className="icon-close" aria-label="Close tooltip"></button>
        </div>
    </>
}