/**
 * Generates a string from the given array of strings inputClasses, filtering out
 * falsy values and joining the remaining with a space. Allows for conditional classes
 * with the following usage:
 * 
 * ```classes("someClass", condition && "someConditionalClass")```
 * 
 * If ```condition``` is true, the return value will be the following:
 * 
 * "someClass someConditionalClass"
 * 
 * If false:
 * 
 * "someClass"
 * 
 * @param {Array<string?>} inputClasses 
 * @returns A string joining the elements in inputClasses with spaces, falsy values removed.
 */
export function classes(...inputClasses) {
    return inputClasses.filter(x => !!x).join(" ");
}