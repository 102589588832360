import { useNavigate } from "react-router-dom";

export default function AgencyDefaultsManagementTabContent() {
    const navigate = useNavigate();

    return (
        <div className="col-sm-12 padding-top">
            <div className="component-page-headers">
                <h3>Agency Defaults Management Tools</h3>
            </div>
            <br />
            <div className="col-md-6">
                <button type="button" className="btn btn--primary"
                    onClick={() => navigate('/defaults')}>
                    <span>Set Agency Defaults</span>
                </button>
            </div>
        </div>
    );
};
