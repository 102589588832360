import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState, useRef } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageHeader from '../PageHeader/PageHeader';
import { policySync } from "../../api/policy-sync";
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import { useNavigate } from "react-router-dom";
import { GdkToasts } from "@gdk/toasts";
import { UserContext } from "../../context/UserContextProvider";

const lobOptionsList = [
    { displayValue: 'Select Line of Business', value: '' },
    { displayValue: 'Personal Auto', value: 'PEAK' },
    { displayValue: 'Commercial', value: 'COMMERCIAL' }
];

const policySyncRequestReasonOptionsList = [
    { displayValue: 'Select Reason for Requesting a Policy Sync' },
    { displayValue: 'Policy data in the AMS has errored' },
    { displayValue: 'Policy data in the AMS is out of sync' },
    { displayValue: 'Did not receive policy download' },
    { displayValue: 'Other' }
];

const defaultValues = {
    policySystem: lobOptionsList[0].value,
    reason: policySyncRequestReasonOptionsList[0].displayValue,
};

export default function IvansPolicySync() {
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);
    const navigate = useNavigate();
    const [callBackResponse, setCallBackResponse] = useState();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });

    const onSubmit = data => {
        policySync({
            policyNumber: data.policyNumber,
            policySystem: data.policySystem,
            reason: data.reason
        }, setCallBackResponse);
    };

    useEffect(() => {
        if (!user.authorizations?.canManageIvansRequests) {
            navigate("/");
        }
    });

    useEffect(() => {
        if (!clientConfiguration.enableIvansRequest) {
            return navigate("/");
        }
    }, []);

    useEffect(() => {
        if (callBackResponse) {
            if (callBackResponse.status === 200)
            {
                toastConfirmationNotification(null, "Your policy download was sent to Ivans.");
                return;
            }

            toastUpdateNotification(null, "Your policy download failed to be sent to Ivans.");
        }

    }, [callBackResponse]);

    
    const [updateToastHeader, setUpdateToastHeader] = useState();
    const [updateToastDescription, setUpdateToastDescription] = useState();
    const gdkUpdateToast = useRef(null);

    useEffect(() => {
        gdkUpdateToast.current = new GdkToasts({
            content: "#update-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });
        gdkUpdateToast.current.hide();
    }, []);

    function toastUpdateNotification(header, description) {
        setUpdateToastHeader(header);
        setUpdateToastDescription(description);
        gdkUpdateToast.current.show();
    }

    const [confirmationToastHeader, setConfirmationToastHeader] = useState();
    const [confirmationToastDescription, setConfirmationToastDescription] = useState();
   
    const gdkConfirmationToast = useRef(null);
    useEffect(() => {
        gdkConfirmationToast.current = new GdkToasts({
            content: "#confirmation-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });


    }, []);

    function toastConfirmationNotification(header, description) {
        setConfirmationToastHeader(header);
        setConfirmationToastDescription(description);
        gdkConfirmationToast.current.show();
    }

  
    return (
        <>
        <div id="update-toast" className="toasts-container">
                <div className="toasts toasts--update">
                    <div>
                        <span className="icon-info" />
                    </div>
                    <div role="status">
                        <div className="toasts--heading">{updateToastHeader}</div>
                        <div className="toasts--content">{updateToastDescription}</div>
                    </div>
                    <button className="toasts--close-btn icon-close">
                        <div id="toasts--base-timer" />
                    </button>
                </div>
            </div>
            <div id="confirmation-toast" className="toasts-container">
                <div className="toasts toasts--confirmation">
                    <div>
                        <span className="icon-confirmation" />
                    </div>
                    <div role="status">
                        <div className="toasts--heading">{confirmationToastHeader}</div>
                        <div className="toasts--content">{confirmationToastDescription}</div>
                    </div>
                    <button className="toasts--close-btn icon-close">
                        <div id="toasts--base-timer" />
                    </button>
                </div>
            </div>
        <form id="form-policy-sync-admin" onSubmit={handleSubmit(onSubmit)}>
            <PageHeader
                header="Ivans Policy Sync"
                showBottomBorder={false}
                children={
                    <>
                        <p>Request a current copy of a policy to be sent to Ivans.</p>
                        <a href="/" style={{ textDecoration: "none" }}>
                            <span className="geico-icon--small icon-chevron-left"></span>
                            Return to Search
                        </a>
                    </>
                }
            />
            <section>
                <div className="container margin-top">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`content form-field ${errors.policyNumber && `form-field--error`}`}>
                                <label htmlFor="policy-number-input" className="text ">Policy Number</label>
                                <input
                                    aria-invalid="true"
                                    data-validate="required"
                                    id="policyNumber"
                                    name="policyNumber"
                                    type="text"
                                    size="50"
                                    maxLength="100"
                                    placeholder="Policy Number"
                                    {...register("policyNumber")}
                                />
                                {errors.policyNumber &&
                                    <span className="form-message">{errors.policyNumber.message}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row margin-top">
                        <div className={"col-lg-6 form-field"}>
                            <div className={`form-field ${errors.policySystem && "form-field--error"}`}>
                                <label htmlFor="policy-line-of-business-select-box" className="text">Policy Line of Business</label>
                                <div className="select-box">
                                    <select
                                        id="policy-line-of-business-select-box"
                                        {...register("policySystem")}>
                                        {lobOptionsList.map((option) =>
                                            <option key={"opt-" + option.displayValue} value={option.value}>{option.displayValue}</option>
                                        )}
                                    </select>
                                </div>
                                {errors.policySystem &&
                                    <span className="form-message">{errors.policySystem.message}</span>
                                }
                            </div>
                        </div>

                        <div className={"col-lg-6 form-field"}>
                            <div className={`form-field ${errors.reason && "form-field--error"}`}>
                                <label htmlFor="reason-for-request-select-box" className="text">Reason for Requesting a Policy Sync</label>
                                <div className="select-box">
                                    <select
                                        id="reason-for-request-select-box"
                                        name="reason-for-request-select-box"
                                        {...register("reason")}>
                                        {policySyncRequestReasonOptionsList.map((option) =>
                                            <option key={"opt-" + option.displayValue} value={option.displayValue}>{option.displayValue}</option>
                                        )}
                                    </select>
                                </div>
                                {errors.reason &&
                                    <span className="form-message">{errors.reason.message}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="padding-top btn-center">
                            <button id="btnSubmitPolicySyncRequest" type="submit" className="btn btn--primary">
                                <span>Submit</span>
                            </button>
                            <button id="btnCancelPolicySyncRequest" type="button" className="btn btn--secondary" onClick={() => reset()}>
                                <span>Clear</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
        </>
    );
}

const validationSchema = yup.object({
    policyNumber: yup.string().required("Enter a policy number."),
    policySystem: yup.string().test("LOBTest", "Select a line of business", (policySystem) => {
        return policySystem !== defaultValues.policySystem;
    }),
    reason: yup.string().test("RequestReasoningTest", "Select a reason for requesting a policy sync", (reason) => {
        return reason !== defaultValues.reason;
    }),
});
