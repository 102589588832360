export default function Error() {
    return <div className="container">
        <div className="margin-top row">
            <div className="hidden-sm col-md-4">
                <img src="images/pose.png" />
            </div>
            <div className="col-sm-12 col-md-8">
                <div className="alert">
                    <div className="alert--high-importance">
                        <span className="icon-alert" />
                        <ul className="alert-list">
                            <li className="alert-content">
                                <div className="h4">Oops! Seems we've encountered an error.</div>
                                <p>
                                    We apologize for the inconvenience.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}