export default function PageHeader({
    header,
    subHeader,
    children,
    showBottomBorder,
    icon,
    backgroundColor
}) {
    let style = {};

    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    }

    return (
        <div className="page-header--wrapper" style={style}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className={`page-header${showBottomBorder ? " page-header-bottom-border" : ""}${subHeader ? "" : " page-header--without-subheader"}`}>
                            <div className="page-header-left">
                                {header && <h2>{header}</h2>}
                                {subHeader && <h4>{subHeader}</h4>}
                                {children}
                            </div>
                            {icon &&
                                <div className="page-header-right">
                                    <span className={`geico-icon ${icon} pull-right`}></span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
