import PageHeader from "../PageHeader/PageHeader";
import { useForm } from "react-hook-form";
import { classes } from "../../util/style-utils";
import useSuffixOptions from "../../hooks/useSuffixOptions";
import useAgentRoleOptions from "../../hooks/useAgentRoleOptions";
import useYesNoOptions from "../../hooks/useYesNoOptions";
import { v4 } from 'uuid';
import useUsStateOptions from "../../hooks/useUsStateOptions";
import { useEffect, useRef } from "react";
import { GdkMultipleSelectBox } from "@gdk/multiple-select-box";
import useCaptiveCarrierOptions from "../../hooks/useCaptiveCarrierOptions";
import { useMask } from "@react-input/mask";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TooltipLabel from "../gdk/TooltipLabel";
import "@gdk/base/dist/js/jquery.maskedinput.geico";
import { GdkSocialSecurityNumber } from "@gdk/social-security-number";

const validationSchema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
    middleName: yup.string().optional(),
    lastName: yup.string().required("Please enter your last name"),
    suffix: yup.string().optional(),
    businessEmail: yup.string().email("Please enter a valid email address").required("Please enter a valid email address"),
    mobilePhone: yup.string().required("Please enter a valid phone number"),
    businessPhone: yup.string().required("Please enter a valid phone number"),
    textOptIn: yup.bool().optional(),
    role: yup.string().required("Please select your agency role"),
    npn: yup.string()
        .min(5, "Please enter a valid NPN.")
        .max(9, "Please enter a valid NPN.")
        .when("role", (role, schema) =>
            ["csr", "authorized-appointment-rep", "other-employee"].some(r => r == role) ?
                schema.optional() :
                schema.required("Please enter a valid NPN.")),
    agencyName: yup.string().required("Please enter the Agency Name."),
    soleProprietor: yup.string().required("Please select Yes or No."),
    fein: yup.string()
        .when("soleProprietor", {
            is: soleProprietor => soleProprietor === "true",
            then: schema => schema
                .min(9, "Please enter a valid FEIN for your agency.")
                .max(9, "Please enter a valid FEIN for your agency.")
                .required("Please enter a valid FEIN for your agency.")
        }),
    ssn: yup.string()
        .when("soleProprietor", {
            is: soleProprietor => soleProprietor === "false",
            then: schema => schema
                .required("Please enter a valid SSN.")
        }),
    lobCommercial: yup.bool(),
    lobPersonal: yup.bool(),
    captiveAgent: yup.bool().required("Please select Yes or No."),
    captiveCarrier: yup.string().when("captiveAgent", {
        is: true,
        then: schema => schema.required("Please select a carrier.")
    }),
    activeEoPolicy: yup.bool().required("Please select Yes or No."),
    intendEoPolicy: yup.bool().when("activeEoPolicy", {
        is: false,
        then: schema => schema.isTrue("Current E&O coverage is required.").required("Please select Yes or No.")
    })
}).test("select-lob", "Please select at least one.", values => values.lobCommercial || values.lobPersonal);

export default function GeaoOnboarding() {
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const suffixOptions = useSuffixOptions();
    const roleOptions = useAgentRoleOptions();
    const yesNoOptions = useYesNoOptions();
    const usStateOptions = useUsStateOptions();
    const captiveCarrierOptions = useCaptiveCarrierOptions();

    const { ref: registerMobilePhoneRef, ...registerMobilePhone } = register("mobilePhone");
    const mobilePhoneMaskRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    const { ref: registerBusinessPhoneRef, ...registerBusinessPhone } = register("businessPhone");
    const businessPhoneMaskRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    useEffect(() => { new GdkMultipleSelectBox({ content: "#states" }); }, []);

    const watchSoleProprietor = watch("soleProprietor");
    const watchCaptiveAgent = watch("captiveAgent");
    const watchEoPolicy = watch("activeEoPolicy");

    const socialSecurityNumber = useRef();
    useEffect(() => {
        if (watchSoleProprietor === "false") {
            socialSecurityNumber.current = new GdkSocialSecurityNumber({ content: "#social-security-number" });
        }
    }, [watchSoleProprietor]);

    const onSubmit = (values) => {
        console.log(values);
    };

    const lobErrorMessage = () => Object.entries(errors).find(e => e[1].type === "select-lob")?.at(1).message;

    return <>
        <PageHeader header="Let's get started." />
        <div className="container margin-top">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className={classes("form-field", errors.firstName && "form-field--error")}>
                    <label htmlFor="firstName" className="text">
                        First Name
                    </label>
                    <input
                        type="text"
                        size="30"
                        maxLength={20}
                        placeholder="First Name"
                        {...register("firstName")}
                    />
                    {errors.firstName && (
                        <span className="form-message">
                            {errors.firstName.message}
                        </span>
                    )}
                </div>

                <div className={classes("form-field", errors.middleName && "form-field--error")}>
                    <label htmlFor="middleName" className="text">
                        Middle Name
                    </label>
                    <input
                        type="text"
                        size="30"
                        maxLength={20}
                        placeholder="Middle Name"
                        {...register("middleName")}
                    />
                    {errors.middleName && (
                        <span className="form-message">
                            {errors.middleName.message}
                        </span>
                    )}
                </div>

                <div className={classes("form-field", errors.lastName && "form-field--error")}>
                    <label htmlFor="lastName" className="text">
                        Last Name
                    </label>
                    <input
                        type="text"
                        size="30"
                        maxLength={20}
                        placeholder="Last Name"
                        {...register("lastName")}
                    />
                    {errors.lastName && (
                        <span className="form-message">
                            {errors.lastName.message}
                        </span>
                    )}
                </div>

                <div className={classes("form-field", errors.suffix && "form-field--error")}>
                    <label htmlFor="suffix" className="text">
                        Suffix
                    </label>
                    <div className="select-box">
                        <select id="suffix" {...register("suffix")}>
                            {suffixOptions?.map(option => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {errors.suffix &&
                        <span className="form-message">{errors.suffix.message}</span>
                    }
                </div>

                <div className={classes("form-field large-text-input", errors.businessEmail && "form-field--error")}>
                    <label htmlFor="business-email" className="text">
                        Business Email Address
                    </label>
                    <input
                        id="business-email"
                        type="email"
                        size="30"
                        maxLength={60}
                        {...register("businessEmail")}
                    />
                    {errors.businessEmail &&
                        <span className="form-message">{errors.businessEmail.message}</span>
                    }
                </div>

                <div className={classes("form-field", errors.mobilePhone && "form-field--error")}>
                    <label htmlFor="mobile-phone" className="text">Mobile Phone</label>
                    <input
                        id="mobile-phone"
                        type="text"
                        className="phone-number"
                        ref={(e) => {
                            registerMobilePhoneRef(e);
                            mobilePhoneMaskRef.current = e;
                        }}
                        {...registerMobilePhone}
                    />
                    {errors.mobilePhone &&
                        <span className="form-message">{errors.mobilePhone.message}</span>
                    }
                </div>

                <div className={classes("form-field", errors.businessPhone && "form-field--error")}>
                    <label htmlFor="business-phone" className="text">Business Phone</label>
                    <input
                        id="business-phone"
                        type="text"
                        className="phone-number"
                        ref={(e) => {
                            registerBusinessPhoneRef(e);
                            businessPhoneMaskRef.current = e;
                        }}
                        {...registerBusinessPhone}
                    />
                    {errors.businessPhone &&
                        <span className="form-message">{errors.businessPhone.message}</span>
                    }
                </div>

                <fieldset className={classes("form-field checkbox-group", errors.textOptIn)} role="radiogroup">
                    <div className="checkbox-wrapper col-1">
                        <div>
                            <input id="text-opt-in" type="checkbox" {...register("textOptIn")} />
                            <label htmlFor="text-opt-in" className="checkbox">
                                Click here to receive text updates on GEICO For Your Agency.  You will receive an invitation to your identified phone number to enroll.
                            </label>
                        </div>
                    </div>
                </fieldset>

                <div className={classes("form-field", errors.role && "form-field--error")}>
                    <label htmlFor="role" className="text">
                        What role do you play in the agency?
                    </label>
                    <div className="select-box">
                        <select id="role" {...register("role")}>
                            {roleOptions?.map(option => (
                                <option value={option.value} key={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {errors.role &&
                        <span className="form-message">{errors.role.message}</span>
                    }
                </div>

                <div className={classes("form-field", errors.npn && "form-field--error")}>
                    <TooltipLabel
                        htmlFor="npn"
                        label="NPN"
                        content="National Producer Number (NPN) is your unique identifier assigned through the NAIC licensing application process."
                    />
                    <input
                        id="npn"
                        type="number"
                        size="30"
                        placeholder="NPN"
                        {...register("npn", { setValueAs: value => value === "" ? undefined : value })}
                    />
                    {errors.npn && (
                        <span className="form-message">
                            {errors.npn.message}
                        </span>
                    )}
                </div>

                <div className={classes("form-field", errors.agencyName && "form-field--error")}>
                    <TooltipLabel
                        htmlFor="agencyName"
                        label="Agency Name"
                        content="Please enter the agency name listed on your tax forms."
                    />
                    <input
                        id="agencyName"
                        type="text"
                        size="30"
                        maxLength={100}
                        placeholder="Agency Name"
                        {...register("agencyName")}
                    />
                    {errors.agencyName && (
                        <span className="form-message">
                            {errors.agencyName.message}
                        </span>
                    )}
                </div>

                <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.soleProprietor && "form-field--error")}>
                    <legend>
                        Are you a Sole Proprietor?
                    </legend>
                    <div className="radio-button-wrapper col-2">
                        {yesNoOptions?.map((option) => {
                            var id = `${option.label}-radio-${v4()}`;
                            return (
                                <div key={id}>
                                    <input
                                        id={id}
                                        type="radio"
                                        value={option.value}
                                        {...register("soleProprietor")}
                                    />
                                    <label className="radio" htmlFor={id}>
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {errors.soleProprietor && (
                        <span className="form-message">
                            {errors.soleProprietor.message}
                        </span>
                    )}
                </fieldset>

                {watchSoleProprietor === "true" &&
                    <div className={classes("form-field", errors.fein && "form-field--error")}>
                        <label htmlFor="fein" className="text">
                            Agency Federal Employer Identification Number (FEIN)
                        </label>
                        <input
                            id="fein"
                            type="number"
                            size="30"
                            placeholder="FEIN"
                            {...register("fein", { setValueAs: value => value === "" ? undefined : value })}
                        />
                        {errors.fein && (
                            <span className="form-message">
                                {errors.fein.message}
                            </span>
                        )}
                    </div>
                }

                {watchSoleProprietor === "false" && <>
                    <div id="social-security-number">
                        <div className={classes("social-security-number-wrapper form-field", errors.ssn && "form-field--error")}>
                            <TooltipLabel
                                htmlFor="ssn"
                                label="Social Security Number"
                                content={<>At GEICO, we understand the importance of your privacy and security. The information you provide will be kept confidential and secure. It will not be sold or redistributed. To learn more about how we protect your personal information, please review our privacy &amp; security policy: <a href="https://media.geico.com/legal/privacy_policy.htm" target="_blank" className=""> Privacy Policy</a></>}
                            />
                            <input id="ssn-input-field" name="ssn-input-field" type="tel" className="social-security-number" autoComplete="off" spellCheck="false" placeholder="___-__-____"
                                {...register("ssn", { setValueAs: _ => socialSecurityNumber.current?.getSocialSecurityNumber() })} />
                            <input type="password" id="ssn-input-value" name="ssn-input-value" className="secureInput" maxLength="11" autoComplete="off" spellCheck="false" aria-hidden="true" />
                            <a className="social-security-show-hide" href="#"><span className="icon-hide" aria-label="Hide"></span></a>
                            {errors.ssn && (
                                <span className="form-message">
                                    {errors.ssn.message}
                                </span>
                            )}
                        </div>
                    </div>
                </>
                }

                <div className={classes("form-field", errors.states && "form-field--error")}>
                    <label htmlFor="states" className="text">
                        What state(s) are you applying to be appointed in?
                    </label>
                    <div>
                        <select multiple id="states" {...register("states")} >
                            <option value=""></option>
                            {usStateOptions?.map(option => (
                                <option value={option.value} key={option.value} >{option.label}</option>
                            ))}
                        </select>
                    </div>
                    {errors.states &&
                        <span className="form-message">{errors.states.message}</span>
                    }
                </div>

                <fieldset className={classes("form-field checkbox-group", lobErrorMessage() && "form-field--error")} role="radiogroup">
                    <legend>
                        What lines of business are you interested in writing with GEICO?
                    </legend>
                    <div className="checkbox-wrapper col-1">
                        <div className="label--description">
                            <input id="lob-checkbox-ppa" type="checkbox" {...register("lobPersonal")} />
                            <label htmlFor="lob-checkbox-ppa" className="checkbox"><span>Personal</span></label>
                        </div>
                        <div className="label--description">
                            <input id="lob-checkbox-commercial" type="checkbox" {...register("lobCommercial")} />
                            <label htmlFor="lob-checkbox-commercial" className="checkbox"><span>Commercial</span></label>
                        </div>
                    </div>
                    {lobErrorMessage() &&
                        <span className="form-message">{lobErrorMessage()}</span>
                    }
                </fieldset>

                <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.captiveAgent && "form-field--error")}>
                    <legend>
                        Are you currently a captive agent with any insurance carrier?
                    </legend>
                    <div className="radio-button-wrapper col-2">
                        {yesNoOptions?.map((option) => {
                            var id = `${option.label}-radio-${v4()}`;
                            return (
                                <div key={id}>
                                    <input
                                        id={id}
                                        type="radio"
                                        value={option.value}
                                        {...register("captiveAgent")}
                                    />
                                    <label className="radio" htmlFor={id}>
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {errors.captiveAgent && (
                        <span className="form-message">
                            {errors.captiveAgent.message}
                        </span>
                    )}
                </fieldset>

                {watchCaptiveAgent === "true" &&
                    <div className={classes("form-field", errors.captiveCarrier && "form-field--error")}>
                        <label htmlFor="captive-carrier" className="text">
                            For which carrier are you a captive agent?
                        </label>
                        <div className="select-box">
                            <select id="captive-carrier" {...register("captiveCarrier")}>
                                {captiveCarrierOptions?.map(option => (
                                    <option value={option.value} key={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        {errors.captiveCarrier &&
                            <span className="form-message">{errors.captiveCarrier.message}</span>
                        }
                    </div>
                }

                <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.activeEoPolicy && "form-field--error")}>
                    <legend>
                        Do you have an active E&O policy?
                    </legend>
                    <div className="radio-button-wrapper col-2">
                        {yesNoOptions?.map((option) => {
                            var id = `${option.label}-radio-${v4()}`;
                            return (
                                <div key={id}>
                                    <input
                                        id={id}
                                        type="radio"
                                        value={option.value}
                                        {...register("activeEoPolicy")}
                                    />
                                    <label className="radio" htmlFor={id}>
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {errors.activeEoPolicy && (
                        <span className="form-message">
                            {errors.activeEoPolicy.message}
                        </span>
                    )}
                </fieldset>

                {watchEoPolicy === "false" &&
                    <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.intendEoPolicy && "form-field--error")}>
                        <legend>
                            Do you intend to get E&O coverage for this appointment?
                        </legend>
                        <div className="radio-button-wrapper col-2">
                            {yesNoOptions?.map((option) => {
                                var id = `${option.label}-radio-${v4()}`;
                                return (
                                    <div key={id}>
                                        <input
                                            id={id}
                                            type="radio"
                                            value={option.value}
                                            {...register("intendEoPolicy")}
                                        />
                                        <label className="radio" htmlFor={id}>
                                            <span>{option.label}</span>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {errors.intendEoPolicy && (
                            <span className="form-message">
                                {errors.intendEoPolicy.message}
                            </span>
                        )}
                    </fieldset>
                }

                <div className="row">
                    <div className="col-sm-12">
                        <button
                            type="submit"
                            className="btn btn--primary btn--full-mobile btn--pull-right"
                        >
                            <span>Continue</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn--destructive btn--full-mobile btn--pull-right"
                            disabled
                        >
                            <span>Create Account</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </>
}

