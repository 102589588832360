import { useEffect, useRef } from "react";
import { GdkFooter } from "@gdk/footer";

const Footer = ({ innerRef }) => {
    let gdkFooter = useRef();

    useEffect(() => {
        gdkFooter.current = new GdkFooter({
            "content": "#primary-footer"
        });
    }, []);

    return (
        <footer id="primary-footer" ref={innerRef}>
            <div className="footer-logo icon-geico"></div>

            <div className="footer-links">
                <ul className="footer-list">
                    <li><a href="yourUrl">Privacy</a></li>
                    <li><a href="https://www.geico.com/privacy/" target="_blank" rel="noreferrer">Personal Data Request &amp; Notice</a></li>
                    <li><a href="yourUrl">Legal &amp; Security</a></li>
                    <li><a href="yourUrl">Feedback</a></li>
                </ul>
            </div>

            <div id="footer-copyright">GEICO © 1996-<span id="footerDate"></span></div>
        </footer>
    );
};

export default Footer;
