import React from 'react';
import {
    VictoryGroup, VictoryBar, VictoryLegend,
    VictoryChart, VictoryAxis, VictoryTooltip
} from 'victory';
import { colors } from '../ReportsHelper';
import { getBarsForTopMiddle, noDataMessage } from './ReportOverviewConstants';

export default function GroupedBarChart({userRole, data, dataField}) {
    const bars = () => getBarsForTopMiddle(userRole).map((bar, index) => ({
        ...bar,
        color: colors[index],
        data: data.map(d => ({ x: d.MonthLabel, y: d[bar.value] }))
    }));

    return ( !data ? <div className='no-data'> {noDataMessage(dataField)} </div> :
        <>
            <br/>
            <VictoryChart 
                height={250} 
                domainPadding={{ x: 30 }}
            >
                <VictoryLegend
                    y={10}
                    orientation="horizontal"
                    gutter={20}
                    style={{ labels: { fontSize: 8 } }}
                    data={bars().map(bar => ({ name: bar.title, symbol: { fill: bar.color} }))}
                />
                <VictoryAxis
                    style={{
                        tickLabels: { fontSize: 8 } 
                    }}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: { fontSize: 8 },
                        grid: {
                            stroke: "#d9d9d9",
                            size: 5,
                        }
                    }}
                />
                <VictoryGroup offset={10}
                    colorScale={colors}
                >
                    {bars().map(bar => (
                        <VictoryBar
                            barWidth={10}
                            data={bar.data}
                            fill={bar.color}
                            labels={({ datum }) => `Month Start Date: ${datum.x}\n${bar.title}: ${datum.y}`}
                            labelComponent={
                                <VictoryTooltip
                                    constrainToVisibleArea
                                    style={{ fill: "white", fontSize: 8 }}
                                    flyoutStyle={{ fill: "#24232a",
                                    stroke:
                                        colors[0],
                                    strokeWidth: 0.5,
                                    }}
                                />
                            }
                        />
                    ))}
                </VictoryGroup>
            </VictoryChart>
        </>
    )
}