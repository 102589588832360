import { useEffect, useState } from "react";

const useContactMethodOptions = () => {
    const [contactMethodOptions, setContactMethodOptions] = useState();

    useEffect(() => {
        setContactMethodOptions([
            { value: "email", text: "Email" },
            { value: "phone", text: "Phone" },
            { value: "portal", text: "GEICO Gateway Portal" },
            { value: "text", text: "Text Message" }
        ]);
    }, []);

    return contactMethodOptions;
};

export default useContactMethodOptions;
