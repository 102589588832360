import { useState } from "react";

export default function useCaptiveCarrierOptions() {
    const [captiveCarrierOptions] = useState([
        { value: "", label: "" },
        { value: "farm-bureau", label: "Farm Bureau" },
        { value: "farmers", label: "Farmers" },
        { value: "allstate", label: "Allstate" },
        { value: "geico", label: "GEICO" },
        { value: "state-farm", label: "State Farm" },
        { value: "other", label: "Other" },
    ]);

    return captiveCarrierOptions;
}