import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useRef, useMemo, useState } from "react";
import { classes } from "../../util/style-utils";
import { updateAgencyProfile } from "../../api/agency-profile";
import { GdkTooltip } from '@gdk/tooltip';
import { v4 } from 'uuid';

export default function QuoteAndPolicyManagementSystemsForm({
    agencyProfile = {},
    setAgencyProfile = () => { },
    expandableForm
}) {
    const [enableButtons, setEnableButtons] = useState(true);

    const defaultValues = useMemo(() => ({
        ivansAccountId: agencyProfile.ivans?.ivansAccountId || "",
        ivansUserId: agencyProfile.ivans?.ivansUserId || ""
    }), [agencyProfile]);

    const { register, handleSubmit, clearErrors, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    useEffect(() => {
        reset(defaultValues);
    }, [agencyProfile, reset, defaultValues]);

    const onSubmit = (values) => {
        if (!values.errors) {
            setEnableButtons(false);

            const updatedAgencyProfile = {
                ...agencyProfile,
                agencyManagementSystem: values.agencyManagementSystem,
                compRater: values.compRater,
                ivans: {
                    ...agencyProfile.ivans,
                    isIvansEnabled: values.ivansDownloads,
                    ivansAccountId: values.ivansAccountId?.toUpperCase(),
                    ivansUserId: values.ivansUserId?.toUpperCase(),
                }
            };

            updateAgencyProfile(updatedAgencyProfile,
                () => {
                    setAgencyProfile(updatedAgencyProfile);
                    expandableForm?.closeExpandedForm(document.getElementById("quote-and-policy-form-li"));
                },
                () => {
                    setEnableButtons(true);
                });
        }
    }

    const onCancel = () => reset();

    const agencyManagementSystemOptions = AgencyManagementSystemOptions();
    const comparativeRaterOptions = ComparativeRaterOptions();
    const ivansDownloadsOptions = IvansDownloadsOptions();

    const ivansAccountIDTriggerIdRef = useRef(`tooltip-trigger-${v4()}`);
    const ivansUserIDTriggerIdRef = useRef(`tooltip-trigger-${v4()}`);

    useEffect(() => { new GdkTooltip({ content: `#${ivansAccountIDTriggerIdRef.current}` }); }, []);
    useEffect(() => { new GdkTooltip({ content: `#${ivansUserIDTriggerIdRef.current}` }); }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className={classes("form-field", errors.agencyManagementSystem && "form-field--error")}>
                <label htmlFor="agency-management-system" className="text">Agency Management System</label>
                <div className="select-box">
                    <select id="agency-management-system" {...register("agencyManagementSystem", { value: agencyProfile.agencyManagementSystem })} >
                        {agencyManagementSystemOptions.map((option) => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.agencyManagementSystem &&
                    <span className="form-message">{errors.agencyManagementSystem.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.compRater && "form-field--error")}>
                <label htmlFor="comp-rater" className="text">Comparative Rater</label>
                <div className="select-box">
                    <select id="comp-rater" {...register("compRater", { value: agencyProfile.compRater })} >
                        {comparativeRaterOptions.map((option) => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.compRater &&
                    <span className="form-message">{errors.compRater.message}</span>
                }
            </div>

            <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.ivansDownloads && "form-field--error")}>
                <legend>IVANS Downloads</legend>
                <div className="radio-button-wrapper col-2">
                    {ivansDownloadsOptions?.map((option) => (
                        <div key={`${option.value}-downloads-radio`}>
                            <input
                                id={`${option.value}-downloads-radio`}
                                type="radio"
                                value={option.value}
                                defaultChecked={agencyProfile.ivans?.isIvansEnabled === option.value}
                                {...register("ivansDownloads")}
                                onClick={() => {
                                    if (option.value === false) {
                                        clearErrors(["ivansAccountId", "ivansUserId"]);
                                    }
                                }}
                            />
                            <label className="radio" htmlFor={`${option.value}-downloads-radio`}><span>{option.label}</span></label>
                        </div>
                    ))}
                </div>
            </fieldset>

            <div className={classes("form-field", errors.ivansAccountId && "form-field--error")}>
                <div className="tooltip-label-container">
                    <label id="label-ivans-account-id" htmlFor="ivans-account-id" className="text label--inline">IVANS Account ID</label>
                    <div className="tooltip-container">
                        <a href="#" id={ivansAccountIDTriggerIdRef.current} className="tooltip-trigger" data-tooltip-view="tooltip-view-ivans-account-id" aria-labelledby="label-ivans-account-id" >
                            <span className="icon-question-mark"></span>
                        </a>
                    </div>
                </div>
                <div id="tooltip-view-ivans-account-id" className="tooltip">
                    <div tabIndex="0">
                        IVANS Account ID (aka "Y Code") begins with ‘Y’ and consists of 4 or 5 alpha-numeric characters
                    </div>
                    <button className="icon-close" aria-label="Close tooltip" />
                </div>
                <input id="ivans-account-id" type="text" size="30"
                    style={{
                        textTransform: 'uppercase'
                    }}
                    {...register("ivansAccountId", { value: agencyProfile.ivans?.ivansAccountId })} />
                {errors.ivansAccountId &&
                    <span className="form-message">{errors.ivansAccountId.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.ivansUserId && "form-field--error")}>
                <div className="tooltip-label-container">
                    <label id="label-ivans-user-id" htmlFor="ivans-user-id" className="text label--inline">IVANS User ID</label>
                    <div className="tooltip-container">
                        <a href="#" id={ivansUserIDTriggerIdRef.current} className="tooltip-trigger" data-tooltip-view="tooltip-view-ivans-user-id" aria-labelledby="label-ivans-user-id" >
                            <span className="icon-question-mark"></span>
                        </a>
                    </div>
                </div>
                <div id="tooltip-view-ivans-user-id" className="tooltip">
                    <div tabIndex="0">
                        IVANS User ID (aka "Mailbox") usually begins with ‘YX’ and consists of 6 or 7 alpha-numeric characters
                    </div>
                    <button className="icon-close" aria-label="Close tooltip" />
                </div>
                <input id="ivans-user-id" type="text" size="30"
                    style={{
                        textTransform: 'uppercase'
                    }}
                    {...register("ivansUserId", { value: agencyProfile.ivans?.ivansUserId })} />
                {errors.ivansUserId &&
                    <span className="form-message">{errors.ivansUserId.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <button
                        type="submit"
                        className="btn btn--primary btn--full-mobile btn--pull-right"
                        disabled={!enableButtons}
                    >
                        <span>Save</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                        onClick={() => onCancel()}
                        disabled={!enableButtons}
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>
    );
};
const ivansAccountIdReg = /^[Yy][a-zA-Z0-9]{3,4}$/gm;
const ivansUserIdReg = /^[A-z][a-zA-Z0-9]{5,6}$/gm;

const validationSchema = yup.object().shape({
    agencyManagementSystem: yup.string().required("Required."),
    compRater: yup.string().required("Required."),
    ivansDownloads: yup.bool().required("Required."),
    ivansAccountId: yup.string().when(['ivansDownloads', 'ivansAccountId'], {
        is: (ivansDownloads, ivansAccountId) => ivansDownloads || ivansAccountId?.length,
        then: () => yup.string().required("IVANS Account ID is required when IVANS Downloads = 'Yes'.").
            min(4, "IVANS Account Id length should be at least 4 characters.").
            max(5, "IVANS Account Id length cannot be greater than 5 characters.").
            matches(ivansAccountIdReg, "IVANS Account Id format failed Validation.")
    }),
    ivansUserId: yup.string().when(['ivansDownloads', 'ivansUserId'], {
        is: (ivansDownloads, ivansUserId) => ivansDownloads || ivansUserId?.length,
        then: () => yup.string().required("IVANS User ID is required when IVANS Downloads = 'Yes'.").
            min(6, "IVANS User ID length should be at least 6 characters.").
            max(7, "IVANS User ID length cannot be greater than 7 characters.").
            matches(ivansUserIdReg, "IVANS User Id format failed Validation.")
    })
},
    [
        ['ivansAccountId', 'ivansAccountId'],
        ['ivansUserId', 'ivansUserId']
    ]);

const AgencyManagementSystemOptions = () => [
    { value: "AMS", text: "AMS 360" },
    { value: "JEN", text: "Jenesis" },
    { value: "SAG", text: "Sagitta" },
    { value: "OTHER", text: "Other" },
    { value: "NOTUSE", text: "Do Not Use" }
];

const ComparativeRaterOptions = () => [
    { value: "EZL", text: "Ezlynx" },
    { value: "VER", text: "Verity" },
    { value: "TURBO", text: "TurboRater" },
    { value: "DRC", text: "DRC" },
    { value: "SF", text: "SureFyre" },
    { value: "OTHER", text: "Other" },
    { value: "PLRATING", text: "PL Rating" },
];

const IvansDownloadsOptions = () => [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

export {
    AgencyManagementSystemOptions,
    ComparativeRaterOptions,
    IvansDownloadsOptions
};
