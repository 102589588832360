import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '../gdk/Tabs/Tabs';
import { useState, useEffect, useContext } from 'react';
import AgentProfile from '../AgentProfile/AgentProfile';
import MyAgents from '../MyAgents/MyAgents';
import AgencyBranches from '../AgencyBranches/AgencyBranches';
import AgencyProfile from '../AgencyProfile/AgencyProfile';
import AgencyGroup from '../AgencyGroup/AgencyGroup';
import ErrorsAndOmissions from '../ErrorsAndOmissions/ErrorsAndOmissions';
import { UserContext } from '../../context/UserContextProvider';
import { getAgentProfile } from '../../api/agent-profile';
import PageHeader from '../PageHeader/PageHeader';
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";

export default function ProfileManagement() {
    const location = useLocation();
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);
    const navigate = useNavigate();

    const getInitialTabIndex = () => {
        if (location.state?.agentId) {
            return Object.keys(tabContent).indexOf("My Agents") + 1;
        }
        else if (location.state?.agencyId) {
            return Object.keys(tabContent).indexOf("Agency Profile") + 1;
        }

        return 1;
    }

    const [agentProfile, setAgentProfile] = useState({
        id: user?.agentId,
        agency: {
            agencyId: user?.agencyId
        },
        firstName: user?.firstName,
        lastName: user?.lastName
    });

    const tabContent = {
        "My Profile": <AgentProfile agentProfile={agentProfile} setAgentProfile={setAgentProfile} />,
        "My Agents": <MyAgents agentId={location.state?.agentId} />,
        "Agency Profile": <AgencyProfile agencyId={user?.agencyId} />,
        ...(clientConfiguration.enableAgencyBranchesTab && 
            { "Agency Branches": <AgencyBranches parentAgencyId={user?.agencyId} /> }
        ),
        ...(clientConfiguration.enableAgencyGroupTab && 
            {"Agency Group": <AgencyGroup /> }
        ),
        "Errors & Omissions": <ErrorsAndOmissions agencyId={user?.agencyId} />
    };

    useEffect(() => {
        if (!user.authorizations?.canManageProfileManagement) {
            navigate("/");
        }
    });

    useEffect(() => getAgentProfile(user?.agentId, agentProfile => setAgentProfile(agentProfile)), []);

    return (
        <>
            <PageHeader
                header="Profile Management"
                showBottomBorder={false}
                children={
                    <>
                        <p>
                            Review information related to your profile with GEICO.<br/>
                            Certain fields can be updated here, but others must be by an administrator or with the National Producer Database.
                        </p>
                        <a href="/" style={{textDecoration:"none"}}>
                            <span className="geico-icon--small icon-chevron-left"></span>
                            Return to Search
                        </a>
                    </>
                }
            />
            <div className="container margin-top">
                <Tabs content={tabContent} initialTabOpen={getInitialTabIndex()} />
            </div>
        </>
    );
};
