import { handleErrors } from "../util/api-utils";

export function getScorecardData(request, successCallback) {
    fetch("api/Reports/RequestScorecardData", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function getOverviewData(request, successCallback) {
    fetch("api/Reports/RequestOverviewData", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function getFilterOptions(successCallback) {
    fetch("api/Reports/RequestFilterOptions", {
        method: "get",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
}

export function getFilterOptionsInternal(substituteAgentId, successCallback) {
    fetch("api/Reports/RequestFilterOptionsInternal?substituteAgentId=" + substituteAgentId, {
        method: "get",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
}

export function getScorecardDataInternal(request, successCallback) {
    fetch("api/Reports/RequestScorecardDataInternal", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function getOverviewDataInternal(request, successCallback) {
    fetch("api/Reports/RequestOverviewDataInternal", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};