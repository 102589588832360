import { handleErrors } from "../util/api-utils";

export function updateAgentProfile(agentProfile, successCallback, finallyCallback) {
    fetch("api/AgentProfile/Update", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(agentProfile)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error)
        .finally(finallyCallback);
};

export function getAgentProfile(agentId, successCallback) {
    fetch("api/AgentProfile/Get", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(agentId)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
}