import { useState, useEffect } from "react"
import SectionHeader from '../gdk/SectionHeader/SectionHeader';
import FileUploadFileSelection from './FileUploadFileSelection';
import FileUploadSubmitting from './FileUploadSubmitting';
import FileUploadComplete from './FileUploadComplete';
import FileUploadState from './FileUploadState';

const FileUploadForm = ({
        agencyProfile,
        fileUploadState,
        setFileUploadState = () => {}
    }) => {

    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ errorMessage, setErrorMessage] = useState();

    return (
        <form>
            <SectionHeader
                    header="File Upload"
                    subText="When editing coverage information, please upload supporting documentation for review."
            />
            { errorMessage && 
                <SectionHeader icon="icon-alert" header={"Important Notice"} subText={errorMessage}/>
            }
            { fileUploadState === FileUploadState.Upload &&
                <FileUploadFileSelection 
                    setErrorMessage={setErrorMessage}
                    selectedFiles={selectedFiles} 
                    setSelectedFiles={setSelectedFiles}
                    setFileUploadState={setFileUploadState}
                />
            }
            { fileUploadState === FileUploadState.Submit &&
                <FileUploadSubmitting 
                    agencyProfile={agencyProfile}
                    selectedFiles={selectedFiles}
                    setFileUploadState={setFileUploadState}
                />
            }
            { fileUploadState === FileUploadState.Complete &&
                <FileUploadComplete 
                    selectedFiles={selectedFiles}
                />
            }
        </form>
    );
};

export default FileUploadForm;