import { createContext, useEffect, useState } from "react";
import { login, internalLogin } from "../api/identity";
import Loader, { Types } from "../components/gdk/Loader";
import SessionExpired from "../components/SessionExpired/SessionExpired";
import Layout from "../components/Layout";
import Error from "../components/Error";
import { useLocation } from 'react-router-dom';

export const UserContext = createContext();

export function UserContextProvider({ children }) {
    const [user, setUser] = useState();
    let location = useLocation();
    useEffect(() => {
        if (location.pathname === "/dashboard" || location.pathname === "/view-reports") {
            internalLogin(response => setUser(response));
        }
        else
        {
            login(response => setUser(response));
        }
    }, []);

    return <UserContext.Provider value={user}>
        {!user &&
            <Loader type={Types.Section} />
        }
        {user &&
            <>{children}</>
        }
    </UserContext.Provider>
}