import React, { Fragment } from "react";

const FileUploadComplete = ({
    selectedFiles = () => [],
}) => {

    return (
        <div className="drop doc-status-wrapper">
            <div className="font--center padding-top">
                <span className="success geico-icon icon-check-mark geico-icon--default geico-icon--informational"/>
                <h3>You've submitted {Array.from(selectedFiles).length} files.</h3>
            </div>
            <div className="doc-upload-status container-padding">
                <div>
                    <h3>Your uploads</h3>
                </div>
                <div className="doc-labels padding-bottom hidden-sm hidden-md">
                    <div>File Name</div>
                    <div>Category</div>
                </div>
                <div className="upload-container">
                { Array.from(selectedFiles).map((file) => (
                        <Fragment key={file.name}>
                            <hr/>
                            <div key={`${file.name}-selected-file-upload-container`}>
                                <div className="doc-upload-files">
                                    <span className="img-icon png-image-icon hidden-sm hidden-md"/>
                                </div>
                                <div className="status-name">
                                    {file.name}
                                </div>
                                <div className="status-category">
                                    {file.selectedUploadCategory}
                                </div>
                                <div className="status-outcome">
                                    <span className="success geico-icon geico-icon--small icon-check-mark"/>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUploadComplete;
