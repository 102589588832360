import React, { useEffect, useRef } from "react";
import { GdkMultipleSelectBox } from '@gdk/multiple-select-box';
import '@gdk/multiple-select-box/dist/styles/gdk-multiple-select-box.css';
import $ from "jquery";


export default function SearchSelectBox(props) {
    const {
        id,
        options = [],
        label,
        multipleSelect = false,
        value,
        onChange,
        setFieldValue = () => { },
        initialValuesArray = [],
        validationError
    } = props,
        gdkMultipleSelectBox = useRef(null),
        idRef = useRef(id);

    const setFieldValueRef = useRef(setFieldValue);

    useEffect(() => {
        gdkMultipleSelectBox.current = new GdkMultipleSelectBox({
            content: `#${idRef.current}`,
            placeholder: ""
        });
    }, [])

    useEffect(() => {
        const $element = $(`#${idRef.current}`);

        // Listening to Select2's select event to set the array of selected options
        $element.on("select2:select", function (event) {
            const selectedOptionsCollection = document.getElementById(
                idRef.current
            ).selectedOptions;
            const selectedValuesArray = Array.from(
                selectedOptionsCollection
            ).map((option) => option.dataset.value);
            setFieldValueRef.current(idRef.current, selectedValuesArray);
        });

        // Listening to Select2's unselect event to set the array of selected options
        $element.on("select2:unselect", function (event) {
            event.preventDefault();
            const selectedOptionsCollection = document.getElementById(
                idRef.current
            ).selectedOptions;
            const selectedValuesArray = Array.from(
                selectedOptionsCollection
            ).map((option) => option.dataset.code);

            setFieldValueRef.current(idRef.current, selectedValuesArray);
        });
    }, []);
    
    return (
        <div className={`form-field ${validationError && "form-field--error"}`}>
            <label htmlFor={id} className="text">{label}</label>
            <div>
                <select multiple={multipleSelect} name={id} id={id} value={value} onChange={onChange}>
                    {options?.map((option) => 
                        <option key={option + "-key"} data-code={option}
                            selected={initialValuesArray && initialValuesArray.length > 0 && initialValuesArray.includes(option)}
                            data-value={option} value={option} >{option}</option>
                    )}
                </select>
            </div>
            {validationError && <span id={`${id}-form-message-error-undefined`} className="form-message">{validationError}</span>}
        </div>
    )
};