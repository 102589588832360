/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef } from 'react';
import { GdkSegmentedControl } from '@gdk/segmented-control';
import { v4 } from 'uuid';

const SegmentedControl = ({
    segment1 = "Segment 1",
    segment2 = "Segment 2",
    setActiveSegment = () => { },
    onChange = () => { }
}) => {
    const segmentedControlRef = useRef();
    const idRef = useRef(`segmented-control-component-${v4()}`);
    const setActiveSegmentRef = useRef(setActiveSegment);
    const onChangeRef = useRef(onChange);

    useEffect(() => {
        segmentedControlRef.current = new GdkSegmentedControl({
            content: `#${idRef.current}`,
            segmentedControlChange: (element) => {
                setActiveSegmentRef.current(segmentedControlRef.current.activeSegment());
                onChangeRef.current(element);
            }
        });
    }, []);

    return (
        <div id={idRef.current} className="col-sm-12">
            <div className="segmented-control-component col-sm-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                <div className="segmented-control">
                    <div className="col-sm-6 sc-draggable drag-drop"></div>
                    <a href="#" className="col-sm-6 dropzone">{segment1}</a>
                    <a href="#" className="col-sm-6 dropzone">{segment2}</a>
                </div>
            </div>
        </div>
    );
};

export default SegmentedControl;
