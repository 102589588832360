import React, { useEffect, useState } from 'react';
import Loader, { Types } from "../../gdk/Loader";
import '../Reports.css';
import AggregateCard from './AggregateCard';
import GroupedBarChart from './GroupedBarChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import StackedHorizontalBarChart from './StackedHorizontalBarChart';
import CardHeader from './CardHeader';
import { UserRoles } from '../ReportsHelper';
import { aggregateCardConsts, getChartConsts, formatProductGroup, tooltipContent } from './ReportOverviewConstants';

export default function ReportOverview({ userRole, data, loading }) {
    const [timeSeriesData, setTimeSeriesData] = useState(data ? getTimeSeriesData(data) : null);
    const [bottomMiddleByBranch, setBottomMiddleByBranch] = useState(true);
    const chartConsts = getChartConsts(userRole);

    useEffect(() => {
        setTimeSeriesData(data ? getTimeSeriesData(data) : null);
    }, [data]);

    const hasMoreThanThreeBranches = new Set(data?.ScoreCardData?.map(d => d.BranchName)).size > 3;

    function getSegmentBreakdown(productGroup) {
        const productToDataField = { "ppa/ba/con": "Ppa_bba_con_new_business", "truck": "Truck_new_business" };
        const breakdown = data.ScoreCardData
            .filter(d => d.ProductGroup === productGroup)
            .reduce((acc, d) => {
                const subGroup = d.ProductSubGroup;
                acc[subGroup] = (acc[subGroup] || 0) + d[productToDataField[productGroup]];
                return acc;
            }, {});
        return Object.entries(breakdown).map(([key, value]) => ({ x: key, y: value }));
    }

    function groupAndSumData(groupBy, sumField) {
        const grouped = data.ScoreCardData.reduce((acc, d) => {
            const key = d[groupBy];
            if (d[sumField] > 0) acc[key] = (acc[key] || 0) + d[sumField];
            return acc;
        }, {})
        const sorted = Object.entries(grouped).sort((a, b) => b[1] - a[1]).map(([key, value]) => ({ name: key, count: value }));
        return sorted.length > 15 ? sorted.slice(0, 15) : sorted;
    }

    function getTimeSeriesData(data) {
        const timeSeriesData = { Truck_bound_premium: {}, Ppa_bba_con_bound_premium: {} };
        const returnData = new Set();

        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 7);

        data.ScoreCardData
            .filter(d => new Date(d.ReportingDate) >= sixMonthsAgo)
            .forEach(entry => {
                const entryDate = new Date(entry.ReportingDate);
                const monthYear = `${entryDate.toLocaleString('default', { month: 'short' })} ${entryDate.getFullYear()}`;
                returnData.add(monthYear);

                Object.keys(timeSeriesData).forEach(key => {
                    timeSeriesData[key][monthYear] = (timeSeriesData[key][monthYear] || 0) + entry[key];
                });
            });

        const labels = Array.from(returnData).reverse();
        const chartData = Object.keys(timeSeriesData).reduce((acc, key) => {
            acc[key] = labels.map(month => ({ x: month, y: timeSeriesData[key][month] || 0 }));
            return acc;
        }, {});

        return chartData;
    }

    return (loading ? <Loader type={Types.Section} /> : !data ? <div className='no-data-container'>No data available</div> :
        <div className="dashboard-body">
            <div className="main-content printable">
                <div className="row">
                    {aggregateCardConsts.map(({ title, value, tooltipContent }) => (
                        <AggregateCard number={data.ScoreCards[value]} title={title} tooltipContent={tooltipContent} />
                    ))}
                </div>
                <div className="row">
                    <div className="card small">
                        <CardHeader title={chartConsts.pif.title} tooltipContent={chartConsts.pif.tooltipContent} />
                        <LineChart
                            data={data.ChartData?.PifData ? Object.values(data.ChartData.PifData.reduce((acc, d) => {
                                const formattedDate = new Date(d.Date).toLocaleString('default', { month: 'short', year: 'numeric' });
                                if (!acc[d.ProductGroup]) {
                                    acc[d.ProductGroup] = [];
                                }
                                acc[d.ProductGroup].push({ x: formattedDate, y: d.Total });
                                return acc;
                            }, {})) : null}
                            labels={[...new Set(data.ChartData?.PifData.map(d => formatProductGroup[d.ProductGroup]))]}
                            dataField={chartConsts.pif.dataDescription}
                        />
                    </div>
                    <div className="card large">
                        <CardHeader title={chartConsts.opportunities.title} tooltipContent={chartConsts.opportunities.tooltipContent} />
                        <GroupedBarChart
                            userRole={userRole}
                            data={data.ChartData?.MonthByMonthChartData}
                            dataField={chartConsts.opportunities.dataDescription}
                        />
                    </div>
                    <div className="card small">
                        <CardHeader title={chartConsts.ppaNewBusiness.title} tooltipContent={tooltipContent.newBusiness} />
                        <StackedHorizontalBarChart
                            data={getSegmentBreakdown("ppa/ba/con")}
                            dataField={chartConsts.ppaNewBusiness.dataDescription}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="card small">
                        <CardHeader title={chartConsts.boundPremium.title} tooltipContent={chartConsts.boundPremium.tooltipContent} />
                        <LineChart
                            data={[timeSeriesData.Ppa_bba_con_bound_premium, timeSeriesData.Truck_bound_premium]}
                            labels={chartConsts.boundPremium.labels}
                            dataField={chartConsts.boundPremium.dataDescription}
                        />
                    </div>
                    <div className="card large">
                        <CardHeader title={chartConsts.combinedNewBusiness.title} tooltipContent={chartConsts.combinedNewBusiness.tooltipContent} />
                        {userRole === UserRoles.Principal ? (
                            hasMoreThanThreeBranches ? (
                                <>
                                    <br/>
                                    <a onClick={() => setBottomMiddleByBranch(prev => !prev)} className="pull-right">
                                        {`Switch to ${bottomMiddleByBranch ? 'Agent' : 'Branch'} View`}
                                    </a>
                                    <div>
                                        <BarChart
                                            data={groupAndSumData(bottomMiddleByBranch ? "BranchName" : "AgentSourceOfBusiness", "All_new_business")}
                                            small
                                            slantLabels
                                            dataField={chartConsts.combinedNewBusiness.dataDescription}
                                        />
                                    </div>
                                </>
                            ) : (
                                <BarChart
                                    data={groupAndSumData(hasMoreThanThreeBranches ? "BranchName" : "AgentSourceOfBusiness", "All_new_business")}
                                    slantLabels
                                    dataField={chartConsts.combinedNewBusiness.dataDescription}
                                />
                            )
                        ) : (
                            <BarChart
                                data={data.ChartData?.MonthByMonthChartData?.map(d => ({ name: d.MonthLabel, count: d.PpaBaConNewBusiness + d.TruckNewBusiness }))}
                                dataField={chartConsts.combinedNewBusiness.dataDescription}
                            />
                        )}
                    </div>
                    <div className="card small">
                        <CardHeader title={chartConsts.truckNewBusiness.title} tooltipContent={chartConsts.truckNewBusiness.tooltipContent} />
                        <StackedHorizontalBarChart
                            data={getSegmentBreakdown("truck")}
                            small
                            dataField={chartConsts.truckNewBusiness.dataDescription}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}