import React from 'react';
import {
    VictoryLine, VictoryAxis, VictoryLegend, VictoryTooltip,
    VictoryScatter, VictoryStack, VictoryChart
} from 'victory';
import { colors, formatNumber } from '../ReportsHelper';
import { noDataMessage } from './ReportOverviewConstants';

export default function LineChart({data, labels, dataField}) {
    const empty = !data || data.every(series => series.length === 0);
    return ( empty ? <div className='no-data'>{noDataMessage(dataField)}</div> :
        <VictoryChart domainPadding={{y: 30}}>
            <VictoryLegend
                y={10}
                orientation="horizontal"
                gutter={20}
                style={{ labels: { fontSize: 12 } }}
                data={ labels.map((label, index) => ({ name: label, symbol: { fill: colors[index] }})) }
            />
            <VictoryAxis
                style={{ tickLabels: { fontSize: 12 } }}
            />
            <VictoryAxis
                dependentAxis
                tickFormat={(x) => formatNumber(x)}
                style={{ 
                    tickLabels: { fontSize: 12 },
                    grid: {
                        stroke: "#d9d9d9",
                        size: 5,
                    }
                }}
            />
            <VictoryStack
                colorScale={colors}
            >
                {data.map((series) => (
                    <VictoryLine
                        interpolation="cardinal"
                        data={series}
                    />
                ))}
            </VictoryStack>
            <VictoryStack
                colorScale={colors}
            >
                {data.map((series, index) => (
                    <VictoryScatter
                        key={index}
                        size={3}
                        data={series}
                        labels={({ datum }) => `Month End Date: ${datum.x}\n${dataField}: ${datum.y}`}
                        labelComponent={
                            <VictoryTooltip
                                constrainToVisibleArea
                                style={{ fill: "white", fontSize: 10 }}
                                flyoutStyle={{ fill: "#24232a",
                                stroke:
                                    colors[0],
                                strokeWidth: 0.5,
                                }}
                            />
                        }
                    />
                ))}
            </VictoryStack>
        </VictoryChart>
    )
}