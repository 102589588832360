import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { classes } from "../../util/style-utils";

const validationSchema = yup.object({
    branchName: yup.string().required("Please enter a branch name."),
    addressLine1: yup.string().required("Please enter the first line of the branch address."),
    addressLine2: yup.string().optional(),
    addressCity: yup.string().required("Please enter the branch city."),
    addressState: yup.string().max(2, "State must be two letters.").matches(/[a-zA-Z]+/g, { message: "State must contain only letters.", excludeEmptyString: true }),
    addressZip: yup.string().required("Please enter the branch ZIP code."),
    addressCounty: yup.string().optional(),
    contactFirstName: yup.string().required("Please enter the first name of the branch contact."),
    contactLastName: yup.string().required("Please enter the last name of the branch contact."),
    role: yup.string().required("Please enter the branch contact's role."),
    email: yup.string().email("Please enter a valid email address.")
});

export default function AgencyBranchesForm({
    agencyBranch = {},
    setAgencyBranch = () => { },
    expandableForm
}) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = (values) => {
        if (!values.errors) {
            const updatedAgencyBranch = {
                ...agencyBranch,
                agencyName: values.branchName,
                businessAddress: {
                    ...agencyBranch.businessAddress,
                    street1: values.addressLine1,
                    street2: values.addressLine2,
                    city: values.addressCity,
                    state: values.addressState,
                    zipCode: values.addressZip,
                    county: values.addressCounty
                },
                branchContact: {
                    ...agencyBranch.branchContact,
                    firstName: values.contactFirstName,
                    lastName: values.contactLastName,
                    role: values.role,
                    email: values.email
                }
            };

            setAgencyBranch(updatedAgencyBranch);
            expandableForm?.closeExpandedForm(document.getElementById("agency-branch-form-li"));
        }
    };

    const onCancel = () => reset();

    useEffect(() => {
        reset();
    }, [agencyBranch, reset]);

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes("form-field large-text-input", errors.branchName && "form-field--error")}>
                <label htmlFor="branch-name" className="text">Branch Name</label>
                <input id="branch-name" type="text" size="30" {...register("branchName", { value: agencyBranch?.name })} />
                {errors.branchName &&
                    <span className="form-message">{errors.branchName.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.addressLine1 && "form-field--error")}>
                <label htmlFor="address-line-1" className="text">Branch Address</label>
                <input id="address-line-1" type="text" size="30" {...register("addressLine1", { value: agencyBranch?.businessAddress?.street1 })} />
                {errors.addressLine1 &&
                    <span className="form-message">{errors.addressLine1.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.addressLine2 && "form-field--error")}>
                <label htmlFor="address-line-2" className="text">Line 2</label>
                <input id="address-line-2" type="text" size="30" {...register("addressLine2", { value: agencyBranch?.businessAddress?.street2 })} />
                {errors.addressLine2 &&
                    <span className="form-message">{errors.addressLine2.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className={classes("form-field large-text-input", errors.addressCity && "form-field--error")}>
                        <label htmlFor="address-city" className="text">City</label>
                        <input id="address-city" type="text" size="30" {...register("addressCity", { value: agencyBranch?.businessAddress?.city })} />
                        {errors.addressCity &&
                            <span className="form-message">{errors.addressCity.message}</span>
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className={classes("form-field", errors.addressState && "form-field--error")}>
                        <label htmlFor="address-state" className="text">State</label>
                        <input id="address-state" type="text" size="5" {...register("addressState", { value: agencyBranch?.businessAddress?.state })} />
                        {errors.addressState &&
                            <span className="form-message">{errors.addressState.message}</span>
                        }
                    </div>
                </div>
            </div>

            <div className={classes("form-field", errors.addressZip && "form-field--error")}>
                <label htmlFor="address-zip-code" className="text">ZIP Code</label>
                <input id="address-zip-code" type="text" size="15" {...register("addressZip", { value: agencyBranch?.businessAddress?.zipCode })} />
                {errors.addressZip &&
                    <span className="form-message">{errors.addressZip.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.addressCounty && "form-field--error")}>
                <label htmlFor="address-county" className="text">County</label>
                <input id="address-county" type="text" size="15" {...register("addressCounty", { value: agencyBranch?.businessAddress?.county })} />
                {errors.addressCounty &&
                    <span className="form-message">{errors.addressCounty.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <div className={classes("form-field", errors.contactFirstName && "form-field--error")}>
                        <label htmlFor="contact-first-name" className="text">Contact First Name</label>
                        <input id="contact-first-name" type="text" size="15" {...register("contactFirstName", { value: agencyBranch?.branchContact?.firstName })} />
                        {errors.contactFirstName &&
                            <span className="form-message">{errors.contactFirstName.message}</span>
                        }
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className={classes("form-field", errors.contactLastName && "form-field--error")}>
                        <label htmlFor="contact-last-name" className="text">Contact Last Name</label>
                        <input id="contact-last-name" type="text" size="15" {...register("contactLastName", { value: agencyBranch?.branchContact?.lastName })} />
                        {errors.contactLastName &&
                            <span className="form-message">{errors.contactLastName.message}</span>
                        }
                    </div>
                </div>
            </div>

            <div className={classes("form-field", errors.role && "form-field--error")}>
                <label htmlFor="role" className="text">Role</label>
                <input id="role" type="text" size="30" {...register("role", { value: agencyBranch?.branchContact?.role })} />
                {errors.role &&
                    <span className="form-message">{errors.role.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.email && "form-field--error")}>
                <label htmlFor="email" className="text">Email</label>
                <input id="email" type="text" size="30" {...register("email", { value: agencyBranch?.branchContact?.email })} />
                {errors.email &&
                    <span className="form-message">{errors.email.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <button type="submit" className="btn btn--primary btn--full-mobile btn--pull-right">
                        <span>Save</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                        onClick={() => onCancel()}
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>
    )
}