import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import PageHeader from '../PageHeader/PageHeader';
import { salesLeaderAgentSearch } from '../../api/search';
import { classes } from '../../util/style-utils';
import { useState } from "react";
import { Link } from 'react-router-dom';

export default function SalesLeaderView() {
    const validationSchema = yup.object({
        agentId: yup.string().matches(/^[iI]\d{0,6}$/, { message: "Invalid Agent Id, ex: I123456", excludeEmptyString: true })
    }).optional();

    const [agents, setAgents] = useState();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            agentId: "",
        }
    });

    const onSubmit = data => {
        const request = {
            ...data,
            agentId: data.agentId.toUpperCase()
        };

        const handleSuccess = (response) => {
            setAgents(response);
        };
        salesLeaderAgentSearch(request, handleSuccess);
    };

    const buttonStyle = {
        "marginBottom": "0",
        "marginTop": "3rem"
    };

    const viewReportsOnClick = agent => {
       localStorage.setItem("substituteAgentId", agent.id);
       localStorage.setItem("substituteAgentName", agent.firstName + " " + agent.lastName);
       var role = agent.jobProfile === "Principal" ? "IA Principal" : "Producer";
       localStorage.setItem("substituteAgentProfile", role);
    };

    return (
        <form className="form agent-search-form" onSubmit={handleSubmit(onSubmit)}>
            <PageHeader header="Agency Management System" showBottomBorder={false}>
                <br />
                <h4>Sales Leader View</h4>
            </PageHeader>
            <div className='container margin-top' >
                <div className="component-page-headers">
                    <h3>Find an agent profile to view reports</h3>
                    <br />
                </div>                    
                <div className="agent-search-fields">
                    <div className="row">
                        <div className="col-sm-3">
                            <div
                                className={classes(
                                    "form-field",
                                    errors.agentId && "form-field--error"
                                )}
                            > 
                                <label htmlFor="agentId" className="text">
                                    Agent ID
                                </label>
                                <input
                                    id="agentId"
                                    className='uppercase-input'
                                    type="text"
                                    size="15"
                                    placeholder="I Code"
                                    {...register("agentId")}
                                    maxLength="7"
                                />
                                {errors.agentId && (
                                    <span className="form-message">{errors.agentId.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <button
                                type="submit"
                                className="btn btn--primary btn--full-mobile btn--pull-left"
                                style={buttonStyle}
                            >
                                <span>Find Agent Profile</span>
                            </button>
                        </div>
                    </div>
                    <span className="stroke-separator"></span>
                        {agents && agents.length === 0 && <p>No results.</p>}
                        {agents && agents.length !== 0 && (
                            <div className="data-table">
                                <table id="AgentSearch_Table" className="table">
                                    <thead>
                                        <tr key="headers">
                                            <th data-sort-method="none">Agent ID</th>
                                            <th className="sort-header">
                                                <a>Name</a>
                                            </th>
                                            <th className="sort-header">
                                                <a>NPN</a>
                                            </th>
                                            <th className="sort-header">
                                                <a>Agency ID</a>
                                            </th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agents.map((agent, index) => (
                                            <tr key={index + "row"}>
                                                <td key={index + "id"}>{agent?.id}</td>
                                                <td key={index + "name"}>
                                                    {agent?.lastName + ", " + agent?.firstName}
                                                </td>
                                                <td key={index + "npn"}>
                                                    {agent?.nationalProducerNumber}
                                                </td>
                                                <td key={index + "agencyid"}>{agent?.agency?.agencyId}</td>
                                                <td>
                                                    <Link target="_blank" to={`/view-reports`} onClick={viewReportsOnClick(agent)}>
                                                      View Report
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                </div>
            </div>
        </form>     
    );
};
