import { useContext, useEffect, useRef, useState } from "react";
import AgentProfile, { AgentFullName } from "../AgentProfile/AgentProfile";
import { GdkMultipleSelectBox } from "@gdk/multiple-select-box";
import $ from 'jquery';
import { searchAgents } from "../../api/search";
import { UserContext } from "../../context/UserContextProvider";

export default function MyAgents({
    agentId
}) {
    const user = useContext(UserContext);

    const [agentProfiles, setAgentProfiles] = useState([]);
    const [selectedAgentProfile, setSelectedAgentProfile] = useState({});
    const [selectedAgentId, setSelectedAgentId] = useState(agentId);

    const agentSelectBoxRef = useRef();

    const onAgentSelectBoxChange = () => {
        setSelectedAgentId(agentSelectBoxRef.current.value);
    };

    const setAgentProfile = (agentProfile) => {
        setSelectedAgentProfile(agentProfile);

        if (agentProfiles.some(p => p)) {
            const updatedAgentProfiles = agentProfiles.map(p => p.id === agentProfile.id ? agentProfile : p);
            setAgentProfiles(updatedAgentProfiles);
        }
    };

    useEffect(() => {
        new GdkMultipleSelectBox({
            "content": "#agent-profile-select-box"
        });

        $("#agent-profile-select-box").on("change", onAgentSelectBoxChange);
    }, []);

    useEffect(() => {
        searchAgents({ agencyId: user?.agencyId }, response => setAgentProfiles(response));
    }, []);

    useEffect(() => {
        setSelectedAgentProfile(agentProfiles.find(p => p.id === selectedAgentId) ?? {});
    }, [selectedAgentId, agentProfiles]);

    useEffect(() => {
        if (agentId) {
            $("#agent-profile-select-box").val(agentId).trigger("change");
        }

        setSelectedAgentId(agentSelectBoxRef.current.value);
    }, [agentProfiles]);

    return (
        <>
            <div className="form-field">
                <label htmlFor="agent-profile-select-box" className="text">Select an agent</label>
                <div>
                    <select id="agent-profile-select-box" ref={agentSelectBoxRef}>
                        {agentProfiles?.map(agentProfile =>
                            <option value={agentProfile.id} key={agentProfile.id}>{AgentFullName(agentProfile)}</option>
                        )}
                    </select>
                </div>
            </div>
            <AgentProfile agentProfile={selectedAgentProfile} setAgentProfile={setAgentProfile} />
        </>
    );
};
