/**
 * Checks the response object for HTTP error codes and validations errors.
 * @param {Response} response The fetch API response object.
 * @returns {any} The HTTP response body as a JavaScript object if the body is JSON, otherwise the body content as a string.
 * @throws {Error} When the response does not have an OK status code or there are validation errors.
 */
export async function handleErrors(response) {
    const bodyText = await response.text();

    const body = (() => {
        try {
            return JSON.parse(bodyText);
        }
        catch {
            return bodyText
        }
    })();

    // Check for FluentValidations errors.
    if (body?.isValid === false && body.errors) {
        throw new Error(JSON.stringify(body.errors, null, 4));
    }

    // Check for HTTP error codes.
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return body;
}