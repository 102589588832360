import { handleErrors } from "../util/api-utils";

export function UploadFile(formData, successCallback) {
    fetch("api/FileUpload/UploadFile", {
        method: "post",
        headers: {
            "Accept": "application/json"
        },
        body: formData
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};