import { GdkNavigation } from "@gdk/navigation";
import { GdkDarkModeSwitch } from "@gdk/dark-mode-switch";
import { useContext, useEffect } from "react";
import { ClientConfigurationContext } from "../context/ClientConfigurationContextProvider";
import { UserContext } from "../context/UserContextProvider";
import { logout } from "../api/identity";

export default function PrimaryNavigation() {
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);

    useEffect(() => {
        new GdkNavigation({ content: "#primary-navigation" });
        new GdkDarkModeSwitch({ content: "#dark-mode-switch" });
    }, []);

    const onClickLogout = () => logout(() => window.location.href = clientConfiguration.logoutUrl);

    return (
        <nav id="primary-navigation" role="navigation">
            <div className="nav-background"></div>

            <div className="panel-wrapper" data-side-panel="menu">
                <div className="nav-menu">
                    <ul className="nav-primary-tier nav-items nav-dark-mode-switch-wrapper">
                        {clientConfiguration.masterSwitch && user && !user.loginRequired &&
                            <>
                                {clientConfiguration.enableProfileManagement && user.authorizations?.canManageProfileManagement &&
                                    <li><a className="nav-menu-item" href="/profiles">Profile Management</a></li>
                                }

                                {clientConfiguration.enableAgencyDefaults && user.authorizations?.canSetAgencyDefaults &&
                                    <li><a className="nav-menu-item" href="/defaults">Agency Defaults</a></li>
                                }

                                {clientConfiguration.enableReports && user.authorizations?.canAccessReports &&
                                    <li><a className="nav-menu-item" href="/reports">Reports</a></li>
                                }
                            </>
                        }

                        <li className="nav-dark-mode-switch">
                            <div id="dark-mode-switch" className="button-switch-container">
                                <div className="button-switch-label-wrapper">
                                    <label htmlFor="dark-mode-switch-checkbox" className="text">Dark mode</label>
                                </div>
                                <input id="dark-mode-switch-checkbox" name="dark-mode-switch-checkbox" type="checkbox" />
                                <div className="button-switch"></div>
                            </div>
                        </li>

                        {user && !user.loginRequired &&
                            <>
                                <li className="nav-additional-links menu">
                                    <div className="nav-bottom-links">
                                        <ul className="list">
                                            <li className="menu">
                                                <span className="geico-icon--small icon-logout icon-padding"></span>
                                                <a href="#" onClick={onClickLogout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
};
