import { handleErrors } from "../util/api-utils";

export function policySync(policySyncRequest, callBackResponse) {
    fetch("api/PolicySync/RequestPolicySync", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(policySyncRequest)
    })
        .then(response => { callBackResponse(response) }) 
        .catch(console.error);
}