import { handleErrors } from "../util/api-utils";

export function login(successCallback) {
    fetch("api/Identity/Login")
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export function internalLogin(successCallback) {
    fetch("api/InternalIdentity/Login")
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};

export async function logout(finallyCallback) {
    fetch("/api/Identity/Logout")
        .then(handleErrors)
        .catch(console.error)
        .finally(finallyCallback);
};