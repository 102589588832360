import React, { useEffect, useRef } from "react";
import { GdkDatePicker } from '@gdk/datepicker'
import '@gdk/datepicker/dist/styles/gdk-datepicker.css';

export default function DatePicker(props){
    const {
        id,
        value,
        monthYearDropdowns = true,
        dateFormatShort = false,
        minDate = new Date(),
        maxDate = new Date(2030, 1 - 1, 1),
        disabled = false,
        onDateSelected = () => { },
        updateMinDate = '',
        updateMaxDate = '',
        dateChanged,
        dateChangedBlur
    } = props,
        gdkDatePicker = useRef(null),
        idRef = useRef(id),
        monthYearDropdownsRef = useRef(monthYearDropdowns),
        dateFormatShortRef = useRef(dateFormatShort),
        minDateRef = useRef(minDate),
        maxDateRef = useRef(maxDate),
        disabledRef = useRef(disabled);

    useEffect(() => {
        gdkDatePicker.current = new GdkDatePicker({
            content: `#${idRef.current}`,
            monthYearDropdowns: monthYearDropdownsRef.current,
            dateFormatShort: dateFormatShortRef.current,
            minDate: minDateRef.current,
            maxDate: maxDateRef.current,
            disabled: disabledRef.current,
            dateSelected: onDateSelected
        });
    }, [])

    useEffect(() => {
        if (updateMinDate?.length > 0) {
            gdkDatePicker.current.setMinDate(updateMinDate);
        }
    }, [updateMinDate])

    useEffect(() => {
        if (updateMaxDate?.length > 0) {
            gdkDatePicker.current.setMaxDate(updateMaxDate);
        }
    }, [updateMaxDate])

    return (
        <input id={id} name={id} className="date date-datepicker" type="tel" placeholder="MM/DD/YYYY" defaultValue={value}
            onChange={dateChanged} onBlur={dateChangedBlur} maxLength="10"
            aria-label="Date. Please enter 2 digit month, 2 digit day, and 4 digit year"></input>
    )
};