import ExpandableFormListItem from "../gdk/ExpandableForm/ExpandableFormListItem";
import ExpandableFormList from "../gdk/ExpandableForm/ExpandableFormList";
import ErrorsAndOmissionsForm, { PolicyCoverageOptions } from "./ErrorsAndOmissionsForm";
import FileUploadForm from '../FileUpload/FileUploadForm';
import FileUploadState from '../FileUpload/FileUploadState';
import { useRef, useState, useEffect } from "react";
import { GdkToasts } from "@gdk/toasts";
import { getAgencyProfile } from "../../api/agency-profile";

const ErrorsAndOmissions = ({
    agencyId
}) => {
    const [expandableForm, setExpandableForm] = useState();
    const [fileUploadState, setFileUploadState] = useState(FileUploadState.Upload);
    const [agencyProfile, setAgencyProfile] = useState();

    useEffect(() => {
        getAgencyProfile(agencyId, response => setAgencyProfile(response));
    }, []);

    const errorsAndOmissionsSummary = (eoInfo) => [
        { name: "Carrier", value: eoInfo?.carrier },
        { name: "Policy Number", value: eoInfo?.policyNumber },
        { name: "Effective Date", value: new Date(eoInfo?.effective).toLocaleDateString("en-US", {timeZone: 'UTC'}) },
        { name: "Expiration Date", value: new Date(eoInfo?.expiration).toLocaleDateString("en-US", {timeZone: 'UTC'}) },
        { name: "Limit per Claim", value: eoInfo?.incidentAmount },
        { name: "Aggregate Claim Amount", value: eoInfo?.totalAmount },
        { name: "Policy Covers the Agency and Principal?", value: PolicyCoverageOptions().find((o) => o.value === (eoInfo?.eoCoversPrincipalAgency === true
            || eoInfo?.eoCoversPrincipalAgency === "Y") ? true : false)?.label
        }
    ];

    const [updateToastHeader, setUpdateToastHeader] = useState();
    const [updateToastDescription, setUpdateToastDescription] = useState();
    const gdkUpdateToast = useRef(null);

    useEffect(() => {
        gdkUpdateToast.current = new GdkToasts({
            content: "#update-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });
    }, []);

    function toastUpdateNotification(header, description) {
        setUpdateToastHeader(header);
        setUpdateToastDescription(description);
        gdkUpdateToast.current.show();
    }

    const [confirmationToastHeader, setConfirmationToastHeader] = useState();
    const [confirmationToastDescription, setConfirmationToastDescription] = useState();
    const gdkConfirmationToast = useRef(null);

    useEffect(() => {
        gdkConfirmationToast.current = new GdkToasts({
            content: "#confirmation-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });
    }, []);

    function toastConfirmationNotification(header, description) {
        setConfirmationToastHeader(header);
        setConfirmationToastDescription(description);
        gdkConfirmationToast.current.show();
    }
    return (
        <>
            <div id="update-toast" className="toasts-container">
                <div className="toasts toasts--update">
                    <div>
                        <span className="icon-info"/>
                    </div>
                    <div role="status">
                        <div className="toasts--heading">{updateToastHeader}</div>
                        <div className="toasts--content">{updateToastDescription}</div>
                    </div>
                    <button className="toasts--close-btn icon-close">
                        <div id="toasts--base-timer"/>
                    </button>
                </div>
            </div>
            <div id="confirmation-toast" className="toasts-container">
                <div className="toasts toasts--confirmation">
                    <div>
                        <span className="icon-confirmation"/>
                    </div>
                    <div role="status">
                        <div className="toasts--heading">{confirmationToastHeader}</div>
                        <div className="toasts--content">{confirmationToastDescription}</div>
                    </div>
                    <button className="toasts--close-btn icon-close">
                        <div id="toasts--base-timer"/>
                    </button>
                </div>
            </div>
            <ExpandableFormList setExpandableForm={setExpandableForm} >
                <ExpandableFormListItem
                    id="eo-info-form-li"
                    headline="Errors and Omissions Coverage"
                    subtext="Required minimum limits $500,000/$500,000"
                    editable={true}
                    summaryItems={errorsAndOmissionsSummary(agencyProfile?.eoInfo)}
                    form={<ErrorsAndOmissionsForm
                        agencyProfile={agencyProfile}
                        setAgencyProfile={setAgencyProfile}
                        expandableForm={expandableForm}
                        fileUploadState={fileUploadState}
                        toastUpdateNotification={toastUpdateNotification}
                        toastConfirmationNotification={toastConfirmationNotification}
                    />}
                />
            </ExpandableFormList>
            <FileUploadForm
                agencyProfile={agencyProfile}
                fileUploadState={fileUploadState}
                setFileUploadState={setFileUploadState}
            />
        </>
    );
};

export default ErrorsAndOmissions;