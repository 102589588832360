import React from 'react';
import AppRouter from './AppRouter';
import Layout from './components/Layout';
import './styles/custom.css';
import './styles/gdk';
import { UserContextProvider } from './context/UserContextProvider';
import { ClientConfigurationContextProvider } from './context/ClientConfigurationContextProvider';

const App = () => {
    return (
        <ClientConfigurationContextProvider>
            <UserContextProvider>
                <Layout>
                    <AppRouter />
                </Layout>
            </UserContextProvider>
        </ClientConfigurationContextProvider>
    );
}

export default App;
