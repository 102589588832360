const ExpandableFormListItem = ({
    headline,
    subHeadline,
    subtext,
    editable = false,
    deletable = false,
    summaryItems,
    form,
    ...rest
}) => {
    return (
        <li {...rest}>
            <div className="expandable-form-headline">
                <div className={`left-side ${!!subtext && "left-with-subtext"}`}>
                    <h4 className="h4">
                        {headline}
                        { subHeadline && 
                            <p>
                                {subHeadline}
                            </p>
                        }
                    </h4>
                    { subtext && 
                        <p>{subtext}</p>
                    }
                </div>
                <div className="right-side">
                    {deletable && <span className="expandable-form-control expandable-form-delete-icon geico-icon--small geico-icon--actionable geico-icon icon-trash"></span>}
                    {editable && <span className="expandable-form-control expandable-form-edit-icon geico-icon--small geico-icon--actionable geico-icon icon-edit"></span>}
                </div>
            </div>
            {summaryItems &&
                <ul className="list list--two-column short-value expandable-form-summary-list">
                    {summaryItems.map((item) =>
                        <li key={item.name} >
                            <div>{item.name}</div>
                            <div>{item.value}</div>
                        </li>
                    )}
                </ul>
            }
            <div className="expandable-form-content-container">
                {form}
            </div>
        </li>
    );
};

export default ExpandableFormListItem;
