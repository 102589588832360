import React, { useEffect, useRef } from "react";
import { v4 } from "uuid";
import { GdkTooltip } from "@gdk/tooltip";

export default function CardHeader({
    title,
    tooltipContent
}) {
    const idRef = useRef(`tooltip-${v4()}`);
    const labelIdRef = useRef(`tooltip-label-${v4()}`);
    const triggerIdRef = useRef(`tooltip-trigger-${v4()}`);
    const tooltipRef = useRef();
    const showTooltipRef = useRef(!!tooltipContent);

    useEffect(() => {
        if (showTooltipRef.current) {
            tooltipRef.current = new GdkTooltip({
                content: `#${triggerIdRef.current}`
            });
        }
    }, []);

    return (
        <>
            <div className="tooltip-label-container card-header">
                <div>{title}</div>
                { tooltipContent && 
                    <div className="tooltip-container">
                        <div id={triggerIdRef.current} className="tooltip-trigger" data-tooltip-view={idRef.current} aria-labelledby={labelIdRef} style={{transform: "scale(0.8)"}}>
                            <span className="icon-question-mark"></span>
                        </div>
                    </div>
                }
            </div>
            <div id={idRef.current} className="tooltip">
                <div tabIndex="0">{tooltipContent}</div>
                <button className="icon-close" aria-label="Close tooltip"></button>
            </div>
        </>
    );
};