const SectionHeader = ({
    icon,
    header,
    subText,
    tags
}) => {

    return (
        <div className="section-header">
            {icon && <span className={`geico-icon ${icon}`}></span>}
            <div className={subText ? "section-header-title-subtext" : "section-header"}>
                {header && <h3>{header}</h3>}
                {subText && <p>{subText}</p>}
                {tags && <p className="tags tags-confirmation">{tags}</p>}
            </div>
        </div>
    );
};

export default SectionHeader;
