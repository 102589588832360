import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GdkAccordion } from "@gdk/accordion";

const Accordion = (props) => {
    const {
        children,
        id,
        initiallyOpenedElement = null,
        accordionOpenClicked = null,
        accordionCloseClicked = null,
        shouldCloseOthers = true, // GDK default is true; shouldCloseOthers is optional, but cannot pass in undefined to use GDK default
    } = props,
        gdkAccordion = useRef(null),
        idRef = useRef(id),
        initiallyOpenedElementRef = useRef(initiallyOpenedElement),
        accordionOpenClickedRef = useRef(accordionOpenClicked),
        accordionCloseClickedRef = useRef(accordionCloseClicked),
        shouldCloseOthersRef = useRef(shouldCloseOthers);

    useEffect(() => {
        const initiallyOpenedElement = initiallyOpenedElementRef.current;
        const accordionOpenClicked = accordionOpenClickedRef.current;
        const accordionCloseClicked = accordionCloseClickedRef.current;
        const shouldCloseOthers = shouldCloseOthersRef.current;

        gdkAccordion.current = new GdkAccordion({
            content: `#${idRef.current}`,
            initiallyOpenedElement: initiallyOpenedElement
                ? `#${initiallyOpenedElement}`
                : null,
            accordionOpenClicked: accordionOpenClicked
                ? accordionOpenClicked
                : null,
            accordionCloseClicked: accordionCloseClicked
                ? accordionCloseClicked
                : null,
            shouldCloseOthers,
        });
    }, []);

    return (
        <ul id={id} className="accordion">
            {children}
        </ul>
    );
};

Accordion.propTypes = {
    id: PropTypes.string.isRequired,
    initiallyOpenedElement: PropTypes.string,
    accordionOpenClicked: PropTypes.func,
    accordionCloseClicked: PropTypes.func,
};

export default Accordion;
