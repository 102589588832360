import React from 'react';
import {
    VictoryBar, VictoryLabel, VictoryLegend, VictoryStack, VictoryVoronoiContainer, VictoryTooltip
} from 'victory';
import { colors } from '../ReportsHelper';
import { noDataMessage } from './ReportOverviewConstants';

export default function StackedHorizontalBarChart({data, small, dataField}) {
    data = data.filter(d => d.y > 0);
    const sum = data.reduce((acc, d) => acc + d.y, 0);
    
    return ( (!data || data.length === 0) ? <div className='no-data'> {noDataMessage(dataField)} </div> :
        <div>
            <VictoryLegend
                y={40}
                height={small ? 150 : 200}
                orientation="vertical"
                gutter={20}
                data={data.map((d, index) => ({
                    name: `${d.x}: ${d.y}`,
                    symbol: { fill:  colors[index] }
                }))}
            />
            <VictoryStack
                colorScale={"blue"}
                height={100}
                containerComponent={
                    <VictoryVoronoiContainer
                        labels={({ datum }) => `Segment: ${datum.x}\nNew Business Count: ${datum.y}`}
                        labelComponent={
                            <VictoryTooltip
                                constrainToVisibleArea
                                style={{ fill: "white", fontSize: 10 }}
                                orientation={"bottom"}
                                flyoutStyle={{
                                    fill: "#24232a",
                                    stroke: colors[0],
                                    strokeWidth: 0.5,
                                }}
                                dx={({ datum }) => -(datum.y/sum) * 175}
                                dy={40}
                                flyoutPadding={{ top: 5, bottom: 5, left: 10, right: 10 }} // Adjust the padding
                                flyoutPointerLength={10}
                            />
                        }
                    />
                }
            >
                {data.map((d, index) => (
                    <VictoryBar
                        horizontal
                        key={index}
                        data={[d]}
                        barWidth={50}
                        style={{ data: { fill: colors[index] } }}
                        labels={`${(d.y/sum*100).toFixed(0)}%`}
                        labelComponent={
                            <VictoryLabel 
                                dy={-40}
                                dx={-(d.y/sum) * 200 + (index % 2 * 5) - 7}
                            />
                        }
                    />
                ))}
            </VictoryStack>
        </div>
    );
}