import Accordion from "../gdk/Accordion/Accordion";
import { useEffect, useState } from "react";
import ExpandableFormList from "../gdk/ExpandableForm/ExpandableFormList";
import ExpandableFormListItem from "../gdk/ExpandableForm/ExpandableFormListItem";
import AdditionalInformationForm, { AssociationsOptions, IncentivesContactOptions, IncentivesParticipationOptions, LanguageOptions, BusinessRelationshipsOptions } from "./AdditionalInformationForm";
import AgencyDetails from "./AgencyDetails";
import QuoteAndPolicyManagementSystemsForm, { AgencyManagementSystemOptions, ComparativeRaterOptions, IvansDownloadsOptions } from "./QuoteAndPolicyManagementSystemsForm";
import { getAgencyProfile } from "../../api/agency-profile";
import Loader from "../gdk/Loader";

export default function AgencyProfile({
    agencyId,
}) {
    const [agencyProfile, setAgencyProfile] = useState();

    useEffect(() => {
        getAgencyProfile(agencyId, response => setAgencyProfile(response));
    }, []);

    const agencyDetails = (agencyProfile) => {
        return {
            agencyName: agencyProfile.name,
            agencyId: agencyProfile.agencyId,
            affiliatedAgencyGroup: agencyProfile.affiliatedAgencyGroup,
            dateAppointed: agencyProfile.appointedDate,
            appointedLinesOfBusiness: agencyProfile.appointedLinesOfBusiness,
            appointedStates: agencyProfile.geicoAppointedStates,
            licensedStates: agencyProfile.licensedStates,
            businessAddress: AddressString(agencyProfile.businessAddress),
            mailingAddress: AddressString(agencyProfile.mailingAddress),
            principalAgent: `${agencyProfile.principal?.name?.firstName ?? ""} ${agencyProfile.principal?.name?.lastName ?? ""}`,
            principalBusinessPhone: `${agencyProfile.principal?.phones?.business ?? ""} ${agencyProfile.principal?.phones?.businessExtension ?? ""}`,
            principalEmail: agencyProfile.principal?.email
        };
    };

    const additionalInformationSummary = (agencyProfile) => {
        const additionalInformation = [
            { name: "Associations", value: agencyProfile.associations?.map((l) => AssociationsOptions().find((o) => o.value === l).text).join(", ") },
            { name: "Business Relationships", value: agencyProfile.maps?.map((x) => BusinessRelationshipsOptions().find((o) => o.value === x.name)?.text).join(", ") },
            { name: "Business City or County", value: agencyProfile.businessAddress?.city ?? agencyProfile.businessAddress?.county },
            { name: "Rewards Participation", value: IncentivesParticipationOptions().find((o) => o.value === agencyProfile.marketingIncentives?.incentivesParticipation)?.label },
            { name: "Languages", value: agencyProfile.languages?.map((l) => LanguageOptions().find((o) => o.value === l).text).join(", ") },
            { name: "Website", value: agencyProfile.website }
        ]
        const incentivesParticipation = IncentivesParticipationOptions().find((o) => o.value === agencyProfile.marketingIncentives?.incentivesParticipation)?.label;
      
        if (incentivesParticipation === "Yes") {
            const incentivesContact = { name: "Rewards Participation Level", value: IncentivesContactOptions().find((o) => o.value === agencyProfile.marketingIncentives?.incentivesContact)?.text };
            additionalInformation.splice(4, 0, incentivesContact);
        }

        return additionalInformation;
    };

    const quoteAndPolicyManagementSystemsSummary = (agencyProfile) => [
        { name: "Agency Management System", value: AgencyManagementSystemOptions().find((o) => o.value === agencyProfile.agencyManagementSystem)?.text },
        { name: "Comparative Rater", value: ComparativeRaterOptions().find((o) => o.value === agencyProfile.compRater)?.text },
        { name: "IVANS Downloads", value: IvansDownloadsOptions().find((o) => o.value === agencyProfile.ivans?.isIvansEnabled)?.label },
        { name: "IVANS Account ID", value: agencyProfile.ivans?.ivansAccountId },
        { name: "IVANS User ID", value: agencyProfile.ivans?.ivansUserId }
    ];

    const [additionalInfoExpandableForm, setAdditionalInfoExpandableForm] = useState();
    const [quoteAndPolicyExpandableForm, setQuoteAndPolicyExpandableForm] = useState();

    return (
        <>
            {!agencyProfile && <Loader />}

            {agencyProfile &&
                <>
                    <Accordion id="agency-details">
                        <AgencyDetails agencyDetails={agencyDetails(agencyProfile)} />
                    </Accordion>
                    <ExpandableFormList setExpandableForm={setAdditionalInfoExpandableForm} >
                        <ExpandableFormListItem
                            id="additional-information-form-li"
                            headline="Additional Information"
                            editable={true}
                            summaryItems={additionalInformationSummary(agencyProfile)}
                            form={<AdditionalInformationForm
                                agencyProfile={agencyProfile}
                                setAgencyProfile={setAgencyProfile}
                                expandableForm={additionalInfoExpandableForm}
                            />}
                        />
                    </ExpandableFormList>
                    <ExpandableFormList setExpandableForm={setQuoteAndPolicyExpandableForm} >
                        <ExpandableFormListItem
                            id="quote-and-policy-form-li"
                            headline="Quote and Policy Management Systems"
                            editable={true}
                            summaryItems={quoteAndPolicyManagementSystemsSummary(agencyProfile)}
                            form={<QuoteAndPolicyManagementSystemsForm
                                agencyProfile={agencyProfile}
                                setAgencyProfile={setAgencyProfile}
                                expandableForm={quoteAndPolicyExpandableForm}
                            />}
                        />
                    </ExpandableFormList>
                </>
            }
        </>
    );
};

const AddressString = (a) => a ? `${a.street1}, ${a.city}, ${a.state}, ${a.zipCode}` : "";
