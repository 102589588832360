import { GdkExpandableForm } from '@gdk/expandable-form';
import { useEffect, useRef } from 'react';
import { v4 } from 'uuid';

const ExpandableFormList = ({
    children,
    setExpandableForm = () => { }
}) => {
    const gdkExpandableFormRef = useRef();
    const idRef = useRef(`expandable-form-${v4()}`);
    const setExpandableFormRef = useRef(setExpandableForm);

    useEffect(() => {
        gdkExpandableFormRef.current = new GdkExpandableForm({ content: `#${idRef.current}` });

        setExpandableFormRef.current(gdkExpandableFormRef.current);
    }, []);

    return (
        <ul id={idRef.current} className="expandable-form">
            {children}
        </ul>
    );
};

export default ExpandableFormList;
