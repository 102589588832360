import { useNavigate } from "react-router-dom";

export default function IvansRequestTabContent() {
    const navigate = useNavigate();

    return (
        <div className="col-sm-12 padding-top">
            <div className="component-page-headers">
                <h3>Ivans Request Tools</h3>
            </div>
            <br />
            <div className="col-md-10">
                <button type="button" className="btn btn--primary" onClick={() => navigate('/policy-sync')}>
                    <span>Policy Sync</span>
                </button>
            </div>
            <br />
            <div className="col-md-10">
                <button type="button" className="btn btn--primary" onClick={() => navigate('/initial-load')}>
                    <span>Initial Load</span>
                </button>
            </div>
        </div>
    );
};
