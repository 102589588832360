import $ from 'jquery';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useRef, useState, useContext } from "react";
import { GdkMultipleSelectBox } from "@gdk/multiple-select-box";
import useBranchOptions from '../../hooks/useBranchOptions';
import useLanguageOptions from '../../hooks/useLanguageOptions';
import useContactMethodOptions from '../../hooks/useContactMethodOptions';
import useContentPreferenceOptions from "../../hooks/useContentPreferencesOptions";
import { useMask } from '@react-input/mask';
import { updateAgentProfile } from '../../api/agent-profile';
import { v4 } from 'uuid';
import { classes } from '../../util/style-utils';
import { GdkTooltip } from '@gdk/tooltip';
import { standardizeAddress } from '../../api/spectrum-address';
import useYesNoOptions from '../../hooks/useYesNoOptions';
import { ClientConfigurationContext } from '../../context/ClientConfigurationContextProvider';

const validationSchema = yup.object({
    agencyBranch: yup.string().nullable().optional(),
    businessPhone: yup.string().nullable().length(14, "Enter a complete phone number.").optional(),
    businessPhoneExt: yup.string().nullable().matches(/^\d*$/, "Enter a number.").optional(),
    mobilePhone: yup.string().nullable().length(14, "Enter a complete phone number.").optional(),
    residentPhone: yup.string().nullable().length(14, "Enter a complete phone number.").optional(),
    faxNumber: yup.string().nullable().length(14, "Enter a complete fax number.").optional(),
    personalEmail: yup.string().nullable().email().optional(),
    languages: yup.array().nullable().optional(),
    contactMethods: yup.array().nullable().optional(),
    contentPreferences: yup.array().nullable().optional(),
    residentAddressLine1: yup.string().required("Please enter the first line of the resident address."),
    residentAddressLine2: yup.string().nullable().optional(),
    residentAddressCity: yup.string().required("Please enter the resident city."),
    residentAddressState: yup.string().required("Please enter the resident state.").max(2, "State must be two letters.").matches(/[a-zA-Z]+/g,
        { message: "State must contain only letters.", excludeEmptyString: true }),
    residentAddressZip: yup.string().required("Please enter the resident ZIP code."),
    mailingAddressLine1: yup.string().nullable().optional(),
    mailingAddressLine2: yup.string().nullable().optional(),
    mailingAddressCity: yup.string().nullable().optional(),
    mailingAddressState: yup.string().nullable().optional(),
    mailingAddressZip: yup.string().nullable().optional()

});

export default function AgentProfileForm({
    agentProfile = {},
    setAgentProfile = () => { },
    expandableForm,
    expandableFormId = ""
}) {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const clientConfiguration = useContext(ClientConfigurationContext);
    const branchOptions = useBranchOptions(agentProfile?.agency?.agencyId);
    const languageOptions = useLanguageOptions();
    const contactMethodOptions = useContactMethodOptions();
    const contentPreferencesOptions = useContentPreferenceOptions();
    const acceptStandardizedAddressOptions = useYesNoOptions();
    const [standardizeAddressResponse, setStandardizeAddressResponse] = useState(null);

    const getMultiSelectValues = (id) => $(`#${id}`).select2("data").map(d => d.id);

    const [enableButtons, setEnableButtons] = useState(true);

    const onSubmit = (values) => {
        if (!values.errors) {
            const residentAddress = values.acceptStandardizedAddress === "true" && addressVerificationSucceeded() ?
                {
                    ...agentProfile.addresses.residentAddress,
                    street1: standardizeAddressResponse.addresses[0].street,
                    street2: standardizeAddressResponse.addresses[0].street2,
                    city: standardizeAddressResponse.addresses[0].city,
                    state: standardizeAddressResponse.addresses[0].state,
                    zipCode: standardizeAddressResponse.addresses[0].zipCode.zip5
                } : {
                    ...agentProfile.addresses.residentAddress,
                    street1: values.residentAddressLine1,
                    street2: values.residentAddressLine2,
                    city: values.residentAddressCity,
                    state: values.residentAddressState,
                    zipCode: values.residentAddressZip
                };

            const updatedAgentProfile = {
                ...agentProfile,
                agency: {
                    ...agentProfile.agency,
                    branchId: values.agencyBranch,
                },
                phones: {
                    ...agentProfile.phones,
                    business: values.businessPhone,
                    businessExtension: values.businessPhoneExt,
                    cell: values.mobilePhone,
                    resident: values.residentPhone,
                    fax: values.fax
                },
                addresses: {
                    ...agentProfile.addresses,
                    residentAddress: residentAddress,
                    mailingAddress: {
                        street1: values.mailingAddressLine1,
                        street2: values.mailingAddressLine2,
                        city: values.mailingAddressCity,
                        state: values.mailingAddressState,
                        zipCode: values.mailingAddressZip
                    }
                },
                email: values.personalEmail,
                languages: getMultiSelectValues(languagesSelectId.current),
                contactMethods: getMultiSelectValues(contactMethodsSelectId.current),
                contentPreferences: getMultiSelectValues(contentPreferencesSelectId.current)
            };

            setEnableButtons(false);

            updateAgentProfile(updatedAgentProfile,
                () => {
                    setAgentProfile(updatedAgentProfile);
                    setStandardizeAddressResponse(null);
                    expandableForm?.closeExpandedForm(document.getElementById(expandableFormId));
                },
                () => {
                    setEnableButtons(true);
                });
        }
    }

    const onCancel = () => { reset(); setStandardizeAddressResponse(null); };
    const watchAll = watch();

    const { ref: registerBusinessPhoneRef, ...registerBusinessPhone } = register("businessPhone", { value: agentProfile.phones?.business ?? "" });
    const maskBusinessPhoneRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    const { ref: registerMobilePhoneRef, ...registerMobilePhone } = register("mobilePhone", { value: agentProfile.phones?.cell ?? "" });
    const maskMobilePhoneRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    const { ref: registerResidentPhoneRef, ...registerResidentPhone } = register("residentPhone", { value: agentProfile.phones?.resident ?? "" });
    const maskResidentPhoneRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    const languagesSelectId = useRef(`languages-${v4()}`);
    const contactMethodsSelectId = useRef(`contact-${v4()}`);
    const contentPreferencesSelectId = useRef(`contentPreferences-${v4()}`);

    useEffect(() => { new GdkMultipleSelectBox({ content: `#${languagesSelectId.current}` }); }, []);
    useEffect(() => { new GdkMultipleSelectBox({ content: `#${contactMethodsSelectId.current}` }); }, []);
    useEffect(() => { new GdkMultipleSelectBox({ content: `#${contentPreferencesSelectId.current}` }); }, []);

    useEffect(() => { $(`#${languagesSelectId.current}`).trigger("change"); });
    useEffect(() => { $(`#${contactMethodsSelectId.current}`).trigger("change"); });
    useEffect(() => { $(`#${contentPreferencesSelectId.current}`).trigger("change"); });

    useEffect(() => { reset(); setStandardizeAddressResponse(null) }, [agentProfile, reset]);

    const mobilePhoneTriggerIdRef = useRef(`tooltip-trigger-${v4()}`);
    const homePhoneTriggerIdRef = useRef(`tooltip-trigger-${v4()}`);

    useEffect(() => { new GdkTooltip({ content: `#${mobilePhoneTriggerIdRef.current}` }); }, []);
    useEffect(() => { new GdkTooltip({ content: `#${homePhoneTriggerIdRef.current}` }); }, []);

    const residentAddressOnBlur = () => {
        standardizeAddress({
            address: {
                street: watchAll.residentAddressLine1 ?? agentProfile.addresses?.residentAddress?.street1,
                street2: watchAll.residentAddressLine2 ?? agentProfile.addresses?.residentAddress?.street2,
                city: watchAll.residentAddressCity ?? agentProfile.addresses?.residentAddress?.city,
                state: watchAll.residentAddressState ?? agentProfile.addresses?.residentAddress?.state,
                zipCode: watchAll.residentAddressZip ?? agentProfile.addresses?.residentAddress?.zipCode
            }
        },
            response => {
                setStandardizeAddressResponse({ ...response, agentId: agentProfile.id });
            });
    }

    const formatStandardizedAddress = (address) => [
        address?.street,
        address?.street2,
        address?.city && address?.city + ",",
        address?.state,
        address?.zipCode.zip5
    ].join(" ");

    const residentAddressMatchesStandardizedAddress = () => {
        return standardizeAddressResponse?.addresses[0].street === watchAll.residentAddressLine1 &&
            standardizeAddressResponse?.addresses[0].street2 === watchAll.residentAddressLine2 &&
            standardizeAddressResponse?.addresses[0].city === watchAll.residentAddressCity &&
            standardizeAddressResponse?.addresses[0].state === watchAll.residentAddressState &&
            standardizeAddressResponse?.addresses[0].zipCode.zip5 === watchAll.residentAddressZip
    };

    const addressVerificationSucceeded = () => {
        return standardizeAddressResponse?.addressVerificationStatus === "Success" &&
            standardizeAddressResponse?.addresses[0].confidenceLevel === 100 &&
            standardizeAddressResponse?.agentId === agentProfile.id;
    }

    const privacyPolicyTooltip = () => {
        return (
            <>
                <div tabIndex="0" style={{ fontSize: '12px' }}>
                    At GEICO, we understand the importance of your privacy and security.
                    The information you provide will be kept confidential and secure.
                    It will not be sold or redistributed.
                    To learn more about how we protect your personal information,
                    please review our privacy & security policy:
                    <span> </span>
                    <a href="#">
                        Privacy Policy
                    </a>
                </div>
                <button className="icon-close" aria-label="Close tooltip" />
            </>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="form-field">
                <label htmlFor="agent-id" className="text">Agent ID</label>
                <input id="agent-id" type="text" size="15" value={agentProfile.id ?? ""} disabled />
            </div>

            <div className={classes("form-field", errors.agencyBranch && "form-field--error")}>
                <label htmlFor="agency-branch" className="text">Agency Branch</label>
                <div className="select-box">
                    <select id="agency-branch" disabled={!clientConfiguration?.enableEditAgencyBranch}  {...register("agencyBranch", { value: agentProfile.agency?.branchId ?? "" })}>
                        <option value="" key="none" >No branch</option>
                        {branchOptions?.map(option => (
                            <option value={option.value} key={option.value} >{`${option.value} - ${option.text}`}</option>
                        ))}
                    </select>
                </div>
                {errors.agencyBranch &&
                    <span className="form-message">{errors.agencyBranch.message}</span>
                }
            </div>

            <div className={classes("form-field row", (errors.businessPhone || errors.businessPhoneExt) && "form-field--error")}>
                <div className="col-sm-12 col-md-7 col-lg-4">
                    <label htmlFor="business-phone" className="text">Business Phone</label>
                    <input
                        disabled
                        id="business-phone"
                        type="text"
                        className="phone-number"
                        ref={(e) => {
                            registerBusinessPhoneRef(e);
                            maskBusinessPhoneRef.current = e;
                        }}
                        {...registerBusinessPhone}
                    />
                    {errors.businessPhone &&
                        <span className="form-message">{errors.businessPhone.message}</span>
                    }
                </div>
                <div className="col-sm-12 col-md-6">
                    <label className="text" htmlFor="business-phone-ext" >Ext.</label>
                    <input disabled id="business-phone-ext" type="text" {...register("businessPhoneExt", { value: agentProfile.phones?.businessExtension ?? "" })} />
                    {errors.businessPhoneExt &&
                        <span className="form-message">{errors.businessPhoneExt.message}</span>
                    }
                </div>
            </div>

            <div className={classes("form-field", errors.mobilePhone && "form-field--error")}>
                <div className="tooltip-label-container">
                    <label id="label-mobile-phone" htmlFor="mobile-phone" className="text label--inline">Mobile Phone</label>
                    <div className="tooltip-container">
                        <a href="#" id={mobilePhoneTriggerIdRef.current} className="tooltip-trigger" data-tooltip-view="tooltip-view-privacy-policy" aria-labelledby="label-mobile-phone" >
                            <span className="icon-question-mark"></span>
                        </a>
                    </div>
                </div>
                <div id="tooltip-view-privacy-policy" className="tooltip">
                    {privacyPolicyTooltip()}
                </div>
                <input
                    id="mobile-phone"
                    type="text"
                    className="phone-number"
                    ref={(e) => {
                        registerMobilePhoneRef(e);
                        maskMobilePhoneRef.current = e;
                    }}
                    {...registerMobilePhone}
                />
                {errors.mobilePhone &&
                    <span className="form-message">{errors.mobilePhone.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.residentPhone && "form-field--error")}>
                <div className="tooltip-label-container">
                    <label id="label-resident-phone" htmlFor="resident-phone" className="text label--inline">Home Phone</label>
                    <div className="tooltip-container">
                        <a href="#" id={homePhoneTriggerIdRef.current} className="tooltip-trigger" data-tooltip-view="tooltip-view-privacy-policy" aria-labelledby="label-resident-phone" >
                            <span className="icon-question-mark"></span>
                        </a>
                    </div>
                </div>
                <div id="tooltip-view-privacy-policy" className="tooltip">
                    {privacyPolicyTooltip()}
                </div>
                <input
                    id="resident-phone"
                    type="text"
                    className="phone-number"
                    ref={(e) => {
                        registerResidentPhoneRef(e);
                        maskResidentPhoneRef.current = e;
                    }}
                    {...registerResidentPhone}
                />
                {errors.residentPhone &&
                    <span className="form-message">{errors.residentPhone.message}</span>
                }
            </div>

            <div className="form-field row">
                <div className="col-sm-12 col-md-7 col-lg-4">
                    <label htmlFor="fax-number" className="text">Fax Number</label>
                    <input
                        disabled
                        id="fax-number"
                        type="text"
                        className="phone-number"
                        value={agentProfile.phones?.fax ?? ""}
                    />
                </div>
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="business-email" className="text">Business Email</label>
                <input id="business-email" type="text" size="30" value={agentProfile.businessEmail ?? ""} disabled />
            </div>

            <div className={`form-field large-text-input ${errors.personalEmail ? 'form-field--error' : ''}`}>
                <label htmlFor="personal-email" className="text">Email</label>
                <input id="personal-email" type="email" size="30" {...register("personalEmail", { value: agentProfile.email ?? "" })} />
                {errors.personalEmail &&
                    <span className="form-message">{errors.personalEmail.message}</span>
                }
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="business" className="text">Business Address</label>
                <input id="business" type="text" size="30" value={agentProfile.addresses?.businessAddress?.street1 ?? ""} disabled />
            </div>

            <div className={classes("form-field large-text-input", errors.mailingAddressLine1 && "form-field--error")}>
                <label htmlFor="mailing" className="text">Mailing Address Line 1</label>
                <input id="mailing" type="text" size="30" {...register("mailingAddressLine1",
                    { value: agentProfile.addresses?.mailingAddress?.street1 ?? "" })}
                />
                {errors.mailingAddressLine1 &&
                    <span className="form-message">{errors.mailingAddressLine1.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.mailingAddressLine2 && "form-field--error")}>
                <label htmlFor="mailing-AddressLine2" className="text">Mailing Address Line 2</label>
                <input id="mailing-AddressLine2" type="text" size="30" {...register("mailingAddressLine2",
                    { value: agentProfile.addresses?.mailingAddress?.street2 ?? "" })}
                />
                {errors.mailingAddressLine2 &&
                    <span className="form-message">{errors.mailingAddressLine2.message}</span>
                }
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className={classes("form-field large-text-input", errors.mailingAddressCity && "form-field--error")}>
                        <label htmlFor="mailing-city" className="text">Mailing Address City</label>
                        <input id="mailing-city" type="text" size="30" {...register("mailingAddressCity",
                            { value: agentProfile.addresses?.mailingAddress?.city ?? "" })}
                        />
                        {errors.mailingAddressCity &&
                            <span className="form-message">{errors.mailingAddressCity.message}</span>
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className={classes("form-field large-text-input", errors.mailingAddressState && "form-field--error")}>
                        <label htmlFor="mailing-state" className="text">Mailing Address State</label>
                        <input id="mailing-state" type="text" size="30" {...register("mailingAddressState",
                            { value: agentProfile.addresses?.mailingAddress?.state ?? "" })}
                        />
                        {errors.mailingAddressState &&
                            <span className="form-message">{errors.mailingAddressState.message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className={classes("form-field large-text-input", errors.mailingAddressZip && "form-field--error")}>
                <label htmlFor="mailing-zip" className="text">Mailing Address Zip</label>
                <input id="mailing-zip" type="text" size="30" {...register("mailingAddressZip",
                    { value: agentProfile.addresses?.mailingAddress?.zipCode ?? "" })}
                />
                {errors.mailingAddressZip &&
                    <span className="form-message">{errors.mailingAddressZip.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.residentAddressLine1 && "form-field--error")}>
                <label htmlFor="residence" className="text">Residential Address Line 1</label>
                <input id="residence"
                    type="text"
                    size="30"
                    {...register("residentAddressLine1", { value: agentProfile.addresses?.residentAddress?.street1 })}
                    onBlur={residentAddressOnBlur}
                />
                {errors.residentAddressLine1 &&
                    <span className="form-message">{errors.residentAddressLine1.message}</span>
                }
            </div>

            <div className={classes("form-field large-text-input", errors.residentAddressLine2 && "form-field--error")}>
                <label htmlFor="address-line-2" className="text">Residential Address Line 2</label>
                <input
                    id="address-line-2"
                    type="text"
                    size="30"
                    {...register("residentAddressLine2", { value: agentProfile.addresses?.residentAddress?.street2 })}
                    onBlur={residentAddressOnBlur}
                />
                {errors.residentAddressLine2 &&
                    <span className="form-message">{errors.residentAddressLine2.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className={classes("form-field large-text-input", errors.residentAddressCity && "form-field--error")}>
                        <label htmlFor="address-city" className="text">City</label>
                        <input
                            id="address-city"
                            type="text"
                            size="30"
                            {...register("residentAddressCity", { value: agentProfile.addresses?.residentAddress?.city })}
                            onBlur={residentAddressOnBlur}
                        />
                        {errors.residentAddressCity &&
                            <span className="form-message">{errors.residentAddressCity.message}</span>
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className={classes("form-field", errors.residentAddressState && "form-field--error")}>
                        <label htmlFor="address-state" className="text">State</label>
                        <input
                            id="address-state"
                            type="text" size="5"
                            {...register("residentAddressState", { value: agentProfile.addresses?.residentAddress?.state })}
                            onBlur={residentAddressOnBlur}
                        />
                        {errors.residentAddressState &&
                            <span className="form-message">{errors.residentAddressState.message}</span>
                        }
                    </div>
                </div>
            </div>

            <div className={classes("form-field", errors.residentAddressZip && "form-field--error")}>
                <label htmlFor="address-zip-code" className="text">ZIP Code</label>
                <input
                    id="address-zip-code"
                    type="text" size="15"
                    {...register("residentAddressZip", { value: agentProfile.addresses?.residentAddress?.zipCode })}
                    onBlur={residentAddressOnBlur}
                />
                {errors.residentAddressZip &&
                    <span className="form-message">{errors.residentAddressZip.message}</span>
                }
            </div>

            {addressVerificationSucceeded() && !residentAddressMatchesStandardizedAddress() &&
                <fieldset role="radiogroup" className={`form-field radio-button-group ${errors.acceptStandardizedAddress && `form-field--error`}`}>
                    <legend>Do you accept the Suggested Address? {formatStandardizedAddress(standardizeAddressResponse?.addresses[0])}</legend>
                    <div className="radio-button-wrapper col-2">
                        {acceptStandardizedAddressOptions?.map((option) => {
                            var id = `${option.label}-radio-${v4()}`;
                            return (
                                <div key={id}>
                                    <input
                                        id={id}
                                        type="radio"
                                        value={option.value}
                                        defaultChecked={false}
                                        {...register("acceptStandardizedAddress")}
                                        required
                                    />
                                    <label
                                        className="radio"
                                        htmlFor={id}>
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </fieldset>
            }

            <div className={classes("form-field", errors.languages && "form-field--error")}>
                <label htmlFor={languagesSelectId.current} className="text">Languages</label>
                <div>
                    <select multiple id={languagesSelectId.current} {...register("languages", { value: agentProfile.languages ?? [] })} >
                        <option value="" key=""></option>
                        {languageOptions?.map(option => (
                            <option value={option.value} key={option.value}>{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.languages &&
                    <span className="form-message">{errors.languages.message}</span>
                }
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="npn" className="text">National Producer Number</label>
                <input id="npn" type="text" size="30" value={agentProfile.nationalProducerNumber ?? ""} disabled />
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="lob-can-sell" className="text">Lines of Business</label>
                <input id="lob-can-sell" type="text" size="30" value={agentProfile.lobCanSell?.join(", ") ?? ""} disabled />
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="license-number" className="text">License Number</label>
                <input id="license-number" type="text" size="30" value={agentProfile?.license?.licenseNumber ?? ""} disabled />
            </div>

            <div className="form-field large-text-input">
                <label htmlFor="license-state" className="text">License State</label>
                <input id="license-state" type="text" size="30" value={agentProfile?.license?.licenseState ?? ""} disabled />
            </div>

            <div className={classes("form-field", errors.contactMethods && "form-field--error")}>
                <label htmlFor={contactMethodsSelectId.current} className="text">Preferred Contact Methods</label>
                <div>
                    <select multiple id={contactMethodsSelectId.current} {...register("contactMethods", { value: agentProfile.contactMethods ?? [] })} >
                        <option value=""></option>
                        {contactMethodOptions?.map(option => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.contactMethods &&
                    <span className="form-message">{errors.contactMethods.message}</span>
                }
            </div>

            <div className={classes("form-field", errors.contentPreferences && "form-field--error")}>
                <label htmlFor={contentPreferencesSelectId.current} className="text">Content Preferences</label>
                <div>
                    <select multiple id={contentPreferencesSelectId.current} {...register("contentPreferences", { value: agentProfile.contentPreferences ?? [] })} >
                        <option value=""></option>
                        {contentPreferencesOptions?.map(option => (
                            <option value={option.value} key={option.value} >{option.text}</option>
                        ))}
                    </select>
                </div>
                {errors.contentPreferences &&
                    <span className="form-message">{errors.contentPreferences.message}</span>
                }
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <button
                        type="submit"
                        className="btn btn--primary btn--full-mobile btn--pull-right"
                        disabled={!enableButtons}
                    >
                        <span>Save</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                        onClick={() => onCancel()}
                        disabled={!enableButtons}
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </form>
    );
};
