import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContextProvider";

export default function SessionExpired() {
    const user = useContext(UserContext);

    useEffect(() => {
        if (user?.loginRequired && process.env.NODE_ENV !== "development") {
            window.location.href = user.loginUrl;
        }
    }, [user]);

    return <div className="container">
        <div className="margin-top row">
            <div className="hidden-sm col-md-4">
                <img src="images/pose.png" />
            </div>
            <div className="col-sm-12 col-md-8">
                {process.env.NODE_ENV !== "development" && (
                    <div className="alert">
                        <div className="alert--high-importance">
                            <span className="icon-alert" />
                            <ul className="alert-list">
                                <li className="alert-content">
                                    <div className="h4">Your session has ended.</div>
                                    <p>
                                        For the protection of your agency and customers, your GEICO session has ended.
                                        Click <a href={user.loginUrl}>here</a> to log back in to the Agency Management System.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                {process.env.NODE_ENV === "development" && (
                    <div className="alert">
                        <div className="alert--medium-importance">
                            <span className="icon-alert" />
                            <ul className="alert-list">
                                <li className="alert-content">
                                    <p>
                                        Instructions on logging in to this application in your local environment can be found
                                        on the <a target="_blank" href="https://geico.visualstudio.com/GEICO%20AP/_wiki/wikis/GEICO-AP.wiki/22710/How-to-manually-add-an-ESSID-cookie-for-local-UI-testing">GEICO AP Wiki</a>.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>;
}