import React, { Fragment, useEffect } from "react";
import FileUploadState from './FileUploadState';
import { UploadFile } from "../../api/file-upload";

const FileUploadSubmitting = ({
    agencyProfile,
    selectedFiles,
    setFileUploadState = () => { },
}) => {
    useEffect(() => {
        const formData = new FormData();
        formData.append("Target", agencyProfile?.agencyId);

        selectedFiles.forEach((file) => {
            formData.append("Files", file);
        });

        UploadFile(formData, () => {
            setFileUploadState(FileUploadState.Complete);
        });
    });

    return (
        <div className="drop doc-status-wrapper">
            <div className="font--center padding-top">
                <h3>Submitting {Array.from(selectedFiles).length} files</h3>
                <p>Feel free to navigate away from this page while files are being submitted.</p>
            </div>
            <div className="doc-upload-status container-padding">
                <div>
                    <h3>Your uploads</h3>
                </div>
                <div className="doc-labels padding-bottom hidden-sm hidden-md">
                    <div>File Name</div>
                    <div>Category</div>
                </div>
                <div className="upload-container">
                    {Array.from(selectedFiles).map((file) => (
                        <Fragment key={file.name}>
                            <hr />
                            <div key={`${file.name}-selected-file-upload-container`}>
                                <div className="doc-upload-files">
                                    <span className="img-icon jpg-image-icon hidden-sm hidden-md" />
                                </div>
                                <div className="status-name">
                                    {file.name}
                                </div>
                                <div className="status-category">
                                    {file.selectedUploadCategory}
                                </div>
                                <div className="status-outcome">
                                    <span
                                        id="your-loader-id-1"
                                        className="loader"
                                        aria-label="Please wait, it is loading"
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUploadSubmitting;
