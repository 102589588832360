import { UserRoles } from "../ReportsHelper"

export const noDataMessage = (dataDescription) => `There is no data available for ${dataDescription} to display.`

export const formatProductGroup = { 'ppa/ba/con': 'PPA/BA/CON', 'truck': 'Truck' }

export const tooltipContent = {
    newBusiness: "A new business is any business that has been on our books for 12 months or less"
}

export const aggregateCardConsts = [
    {
        value: "PpaBaConOpportunities",
        title: "PPA/BA/CON Opportunities",
        tooltipContent: "Count of all PPA, Business Auto and Contractor quotes combined"
    },
    {
        value: "PpaBaConNewBusiness",
        title: "PPA/BA/CON New Business",
        tooltipContent: "Count of all PPA, Business Auto and Contractor new business apps combined"
    },
    {
        value: "PpaBaConClosure",
        title: "PPA/BA/CON Closure",
        tooltipContent: "All PPA/BA/CON new business divided by all PPA/BA/CON quote count"
    },
    {
        value: "TruckOpportunities",
        title: "Truck Opportunities",
        tooltipContent: "Count of all Truck quotes combined"
    },
    {
        value: "TruckNewBusiness",
        title: "Truck New Business",
        tooltipContent: "Count of all Truck new business apps combined"
    },
    {
        value: "TruckClosure",
        title: "Truck Closure",
        tooltipContent: "All Truck new business divided by all Truck quote count"
    }
]

export function getChartConsts(role) {
    return {
        pif: {
            title: "Month-end PIF",
            dataDescription: "PIF",
            tooltipContent: "Policies in Force for PPA, Business Auto and Contractors combined and Truck"
        },
        opportunities: role === UserRoles.principal
            ? {
                title: "Opportunities & New Business by Date",
                dataDescription: "opportunities or new business",
                tooltipContent: "Month over Month view for the last 6 months PPA/BA/CON Opportunities Counts, PPA/BA/CON New Business Counts, Truck Opportunities Counts, Truck New Business Counts"
            }
            : {
                title: "Opportunities by Date",
                dataDescription: "opportunities",
                tooltipContent: "Month over Month view for the last 6 months PPA/BA/CON Opportunities Counts, and Truck Opportunities Counts"
            },
        ppaNewBusiness: {
            title: "PPA/BA/CON New Business by Segment",
            dataDescription: "ppa/ba/con new business",
            tooltipContent: "Monthly new business apps for PPA - standard, non standard and preferred risk segments, and business auto, and contractors"
        },
        boundPremium: {
            title: "Bound Premium",
            dataDescription: "bound premium",
            labels: ["PPA/BA/CON", "Truck"],
            tooltipContent: "Total bound premium by month for PPA, Business Auto and Contractors combined and Truck"
        },
        combinedNewBusiness: {
            title: "Combined New Business",
            dataDescription: "new business",
            tooltipContent: role === UserRoles.principal ? null : "Month over month view for new business for all"
        },
        truckNewBusiness: {
            title: "Truck New Business by Segment",
            dataDescription: "truck new business",
            tooltipContent: "Monthly new business apps for Truck by classification"
        },
    }
}

export function getBarsForTopMiddle(role) {
    const producer = [
        {
            title: "PPA/BA/CON Opportunities",
            value: "PpaBaConOpportunities"
        },
        {
            title: "Truck Opportunities",
            value: "TruckOpportunities"
        }
    ]
    const principal = [
        ...producer,
        {
            title: "PPA/BA/CON New Business",
            value: "PpaBaConNewBusiness"
        },
        {
            title: "Truck New Business",
            value: "TruckNewBusiness"
        }
    ]
    return role === "IA Principal" ? principal : producer;
}