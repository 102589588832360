
const AgencyDetails = ({
    agencyDetails = {}
}) => {
    let licensedStates = agencyDetails.licensedStates ? agencyDetails.licensedStates.join(', ') : '';
    return (
        <>
            <li>
                <div tabIndex="0" className="accordion-headline">
                    <span className="geico-icon geico-icon--actionable icon-business-ins"></span>
                    <div className="accordion-headline-content-wrapper">
                        <div className="accordion-left-content-wrapper">
                            <div className="heading h4">{agencyDetails.agencyName}</div>
                        </div>
                    </div>
                </div>
                <div className="accordion-content-container">
                    <div className="accordion-content container">
                        <ul className="list list--two-column">
                            <li>
                                <div>Agency ID</div>
                                <div>{agencyDetails.agencyId}</div>
                            </li>
                            <li>
                                <div>Affiliated Agency Group</div>
                                <div>{agencyDetails.affiliatedAgencyGroup}</div>
                            </li>
                            <li>
                                <div>Date Appointed with GEICO</div>
                                <div>{agencyDetails.dateAppointed}</div>
                            </li>
                            <li>
                                <div>Appointed Lines of Business</div>
                                <div>{agencyDetails.appointedLinesOfBusiness}</div>
                            </li>
                            <li>
                                <div>Appointed States</div>
                                <div>{agencyDetails.appointedStates}</div>
                            </li>
                            <li>
                                <div>Licensed States</div>
                                <div>{licensedStates}</div>
                            </li>
                            <li>
                                <div>Business Address</div>
                                <div>{agencyDetails.businessAddress}</div>
                            </li>
                            <li>
                                <div>Mailing Address</div>
                                <div>{agencyDetails.mailingAddress}</div>
                            </li>
                            <li>
                                <div>Principal Agent</div>
                                <div>{agencyDetails.principalAgent}</div>
                            </li>
                            <li>
                                <div>Principal Business Phone</div>
                                <div>{agencyDetails.principalBusinessPhone}</div>
                            </li>
                            <li>
                                <div>Principal Email</div>
                                <div>{agencyDetails.principalEmail}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </>
    );
};

export default AgencyDetails;
