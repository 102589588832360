import { GdkTabs } from "@gdk/tabs";
import { useEffect, useRef } from "react";
import { v4 } from "uuid";

const Tabs = ({
    content = {},
    initialTabOpen = 1
}) => {
    const idRef = useRef(`tabs-${v4()}`);
    const gdkTabsRef = useRef();
    const initialTabOpenRef = useRef(initialTabOpen);

    useEffect(() => {
        gdkTabsRef.current = new GdkTabs({
            content: `#${idRef.current}`,
            initialTabOpen: initialTabOpenRef.current
        });
    }, []);

    return (
        <div id={idRef.current} className="tabs">
            <div className="tabs-container">
                {Object.keys(content).map((title) =>
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid -- Implemented per the GDK documentation
                    <a key={`tab-key-${title}`} className="tab" href="#" role="tab" aria-controls={`panel-${title}`}>
                        <span>{title}</span>
                    </a>
                )}
            </div>

            {Object.keys(content).map((title) =>
                <div id={`panel-${title}`} key={`panel-key-${title}`} className="panel" role="tabpanel" aria-labelledby={`tab-${title}`}>
                    <div className="panel-content">{content[title]}</div>
                </div>
            )}
        </div>
    );
};

export default Tabs;
