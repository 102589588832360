import { useEffect, useState } from "react";
import { searchAgencies } from "../api/search";

export default function useBranchOptions(agencyId) {
    const [branchOptions, setBranchOptions] = useState();

    useEffect(() => {
        searchAgencies(
            { parentAgencyId: agencyId, isBranch: true },
            response => setBranchOptions(response.map(b => ({ value: b.agencyId, text: b.name })))
        );
    }, [agencyId]);

    return branchOptions;
};
