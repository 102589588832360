import ProfileManagement from "./components/ProfileManagement/ProfileManagement";
import Home from "./components/Home/Home";
import AgencyDefaults from "./components/AgencyDefaults/AgencyDefaults";
import IvansPolicySync from "./components/IvansPolicySync/IvansPolicySync";
import IvansInitialLoad from "./components/IvansInitialLoad/IvansInitialLoad";
import ReportPage from "./components/Reports/ReportPage";
import SalesLeaderReportPage from "./components/Reports/SalesLeaderReportPage";
import Dashboard from "./components/Dashboard/SalesLeaderView";

import Error from "./components/Error";
import GeaoOnboarding from "./components/GeaoOnboarding/GeaoOnboarding";
import { Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./context/UserContextProvider";
import SessionExpired from "./components/SessionExpired/SessionExpired";

const AppRoutes = [
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        index: true,
        element: <Home />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/profiles',
        element: <ProfileManagement />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/defaults',
        element: <AgencyDefaults />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/policy-sync',
        element: <IvansPolicySync />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/initial-load',
        element: <IvansInitialLoad />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/reports',
        element: <ReportPage />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/view-reports',
        element: <SalesLeaderReportPage />
    },
    {
        authorize: user => user.authorizations?.canViewAgencyIdentity,
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        authorize: _ => true,
        path: '/onboarding',
        element: <GeaoOnboarding />
    },
    {
        authorize: user => user.sessionExpired || user.loginRequired,
        path: "*",
        element: <SessionExpired />
    },
    {
        authorize: user => !(user.sessionExpired || user.loginRequired),
        path: "*",
        element: <Error />
    }
];

export default function AppRouter() {
    const user = useContext(UserContext);

    return <Routes>
        {AppRoutes
            .filter(route => route.authorize(user))
            .map((route, index) => <Route key={index} {...route} />)}
    </Routes>
}
