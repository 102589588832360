import { useState } from "react";

export default function useAgentRoleOptions() {
    const [suffixOptions] = useState([
        { value: "principal", label: "Principal/Owner" },
        { value: "producer", label: "Producer" },
        { value: "csr", label: "CSR" },
        { value: "branch-manager", label: "Branch Manager" },
        { value: "authorized-appointment-rep", label: "Authorized Appointment Rep" },
        { value: "other-employee", label: "Other Employee" }
    ]);

    return suffixOptions;
}