import { handleErrors } from "../util/api-utils";

/**
 * Requests an initial load for an agency
 * @param {any} initialLoadRequest The initial load request
 * @param {function} successCallback The success callback function.
 * @param {function} errorCallback The error callback function.
 * @param {function} finallyCallback The finally callback function.
 */
export function initialLoad(initialLoadRequest, successCallback, errorCallback, finallyCallback) {
    fetch("api/InitialLoad/RequestInitialLoad", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(initialLoadRequest)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(errorCallback)
        .finally(finallyCallback);
}