import { useEffect, useState } from "react";

const useContentPreferenceOptions = () => {
    const [contentPreferenceOptions, setContentPreferenceOptions] = useState();

    useEffect(() => {
        setContentPreferenceOptions([
            { value: "GNEWS", text: "GEICO news" },
            { value: "IANEWS", text: "IA channel news, trends and data" },
            { value: "AUTOP", text: "Personal Lines products" },
            { value: "AUTOB", text: "Commercial Lines products" },
            { value: "AUTOBLIGHT", text: "Commercial Auto: Light duty" },
            { value: "AUTOBHEAVY", text: "Commercial Auto: Heavy truck" },
            { value: "TRAINING", text: "Product training" },
            { value: "NBUS", text: "New business opportunities" },
            { value: "INCENTIVES", text: "Incentives" },
            { value: "MARKETING", text: "Marketing resources, trends and tips" },
            { value: "INSIGHTS", text: "Customer insights and lead generation" },
            { value: "BOPERATIONS", text: "Business operations tips, training and support" },
            { value: "AGENTEVENT", text: "Agent event and activity opportunities" },
            { value: "SPONSOREDEVENT", text: "Sponsored associations, conferences and events" }
        ]);
    }, []);

    return contentPreferenceOptions;
};

export default useContentPreferenceOptions;
