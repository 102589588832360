import React, { useContext, useEffect, useState } from "react";
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import Loader, {Types} from "../gdk/Loader";
import ReportHeader from "./ReportHeader";
import ReportOverview from "./Overview/ReportOverview";
import { ReportState } from "./ReportsHelper";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import ReportScorecard from "./Scorecard/ReportScorecard";
import { getOverviewData, getOverviewDataInternal } from "../../api/external-reports";

export default function ReportPage ( {substituteAgentInfo} ) {
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);
    const navigate = useNavigate();
    const [view, setView] = useState(ReportState.Overview);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [scorecardExpandedRows, setScorecardExpandedRows] = useState([]);

    useEffect(() => {
        if (!user.authorizations?.canAccessReports || (!clientConfiguration.enableReports && !substituteAgentInfo))
        {
            navigate("/");
        }
    });

    async function loadData() {
        const handleSuccess = (response) => {
            if (response.Status === 3) {
                setData(response.Domain);
            } else if (response.Status === 6) {
                setData(null);
            }
            setLoading(false);
        }

        if(substituteAgentInfo && substituteAgentInfo.id) {
            const request = {
                substituteAgentId: substituteAgentInfo.id,
                ...selectedFilters
            };
            await getOverviewDataInternal(request, response => handleSuccess(response))
        } else {
            const request = {
                ...selectedFilters,
                AgentIds: selectedFilters?.AgentIds ? selectedFilters.AgentIds.map(agent => agent.split('-')[0]) : null
            }
            await getOverviewData(request, response => handleSuccess(response))
        }
    }

    useEffect(() => {
        if (!data) { loadData() }
    });

    function applyFilters() {
        setLoading(true);
        loadData();
    }

    const switchView = () => {
        setView(view === ReportState.Overview ? ReportState.Detailed : ReportState.Overview);
    }

    return (
        <>
            {(clientConfiguration.enableReports || substituteAgentInfo) && (
                <div>
                    <ReportHeader 
                        title={view} 
                        switchView={switchView}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        applyFilters={applyFilters}
                        substituteAgentInfo={substituteAgentInfo || null}
                    />
                    {loading ? <Loader type={Types.Section} /> :
                        <div className="report-body">
                            {view === ReportState.Overview && <ReportOverview
                                userRole={substituteAgentInfo ? substituteAgentInfo.role : user.role}
                                data={data}
                                setData={setData}
                            />}
                            {view === ReportState.Detailed && <ReportScorecard 
                                userRole={substituteAgentInfo ? substituteAgentInfo.role : user.role}
                                data={data}
                                setData={setData}
                                expandedRows={scorecardExpandedRows}
                                setExpandedRows={setScorecardExpandedRows}
                            />}
                        </div>
                    }
                </div>         
            )}
        </>
    );
}