import React, { useState, useContext, useEffect } from 'react';
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import { UserContext } from "../../context/UserContextProvider";
import './Reports.css';
import { switchViewLinkText, getAvailableFilters, capitalizeName, colors } from './ReportsHelper.js';
import DatePicker from './DatePicker.js';
import SearchSelectBox from './SearchSelectBox.js';
import { getFilterOptions, getFilterOptionsInternal } from '../../api/external-reports.js';

export default function ReportHeader({ title, switchView, selectedFilters, setSelectedFilters, applyFilters, substituteAgentInfo }) {
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filterOptions, setFilterOptions] = useState(null);
    const [unappliedDateChanges, setUnappliedDateChanges] = useState(false);

    async function loadFilterOptions() {
        const handleSuccess = (response) => {
            if (response.Status === 3) {
                let options = response.Domain;
                if (options.AgentProfiles?.length > 0) {
                    options.AgentProfiles = options.AgentProfiles.map(agent => `${agent.Id}- ${capitalizeName(agent.FirstName)} ${capitalizeName(agent.LastName)}`);
                }
                setFilterOptions(options);
            }
        }

        if(substituteAgentInfo && substituteAgentInfo.id){
            await getFilterOptionsInternal(substituteAgentInfo.id, response => handleSuccess(response))
        } else {
            await getFilterOptions(response => handleSuccess(response))
        }
    }

    useEffect(() => {
        if (filterOptions == null) { loadFilterOptions() };
    });

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };
    
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    const minDate = new Date(2024, 1, 1);
    const defaultMessage = `The data shown below is up to date as of ${yesterday.toDateString()}.`;

    const [startDate, setStartDate] = useState(formatDate(sixMonthsAgo));
    const [endDate, setEndDate] = useState(formatDate(yesterday));

    function handleStartDateChange(e) { 
        setStartDate(e); 
        setSelectedFilters(prevFilters => ({...prevFilters, StartDate: new Date(e)})) 
        setUnappliedDateChanges(true);
    }
    function handleEndDateChange(e) { 
        setEndDate(e);
        setSelectedFilters(prevFilters => ({...prevFilters, EndDate: new Date(e)}))
        setUnappliedDateChanges(true);
    }

    const availableFilters = getAvailableFilters(substituteAgentInfo ? substituteAgentInfo.role : user.role);

    const handleApplyFilters = () => {
        applyFilters();
        setUnappliedDateChanges(false);
        setIsDropdownOpen(false);
    }

    const handleFilterChange = (filterId, selectedOptions) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [filterId]: selectedOptions
        }));
    }

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            {
                substituteAgentInfo ? <div className='user-info'><b>{`Welcome, ${substituteAgentInfo.name} (Username: ${substituteAgentInfo.id})`}</b></div> : 
                <div className='user-info'><b>{`Welcome, ${user.firstName} ${user.lastName} (Username: ${user.agentId})`}</b></div>
            }
            
            <div className='report-header page-header--wrapper'>
                <span className="title">{title}</span>
                <span className="message" style={{color:colors[1]}}>{clientConfiguration.reportHeaderMessage || defaultMessage}</span>
                <div className="filters">
                    <a className='toggle-link' style={{color:colors[1]}} onClick={switchView}> {switchViewLinkText(title)} </a>
                    <div className='date-range'>
                        <div className='date-filter'>
                            <DatePicker id="start-date" label="Start Date" value={startDate} minDate={minDate}
                                updateMaxDate={endDate} onDateSelected={handleStartDateChange} />  
                        </div>
                        <div className='date-filter'>
                            <DatePicker id="end-date" label="End Date" value={endDate} updateMinDate={startDate}
                                maxDate={yesterday} onDateSelected={handleEndDateChange} /> 
                        </div>
                        <button
                            className='btn btn--primary date-filter'
                            disabled={!unappliedDateChanges}
                            onClick={handleApplyFilters}
                        >
                            Apply
                        </button>
                    </div> 
                    <div className="icon-button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <span className="geico-icon geico-icon--small icon-filter"/>
                    </div>
                    {clientConfiguration.enablePrintReportButton && title === "Overview" &&
                        <div className="icon-button" onClick={handlePrint}>
                            <span className="geico-icon geico-icon--small icon-print"/>
                        </div>
                    }
                </div>
            </div>
            { isDropdownOpen && 
            <div className='filters-dropdown'>
                <div className="filters-container">
                    { availableFilters.map((filter) => (
                        <div className='filter-item' key={filter.value}>
                            <SearchSelectBox
                                id={`multiselect-${filter.value}`}
                                setFieldValue={(e, values) => {handleFilterChange(filter.value, values)}}
                                value={selectedFilters[filter.value]}
                                options={filterOptions && filterOptions[filter.optionsKey]?.filter(option => option !== null)}
                                label={filter.label}
                                multipleSelect={true}
                             />
                        </div>
                    ))}
                </div>
                <button 
                    className="btn btn--primary" 
                    onClick={handleApplyFilters}> 
                    Apply Filters
                </button>
            </div>
            }
        </>
    );
}