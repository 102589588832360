import React, { useContext, useEffect, useState } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import ProfileSearch from '../ProfileSearch/ProfileSearch';
import Tabs from '../gdk/Tabs/Tabs';
import AgencyDefaultsManagementTabContent from './AgencyDefaultsManagementTabContent';
import IvansRequestTabContent from './IvansRequestTabContent';
import ReportsTabContent from './ReportsTabContent';
import { ClientConfigurationContext } from '../../context/ClientConfigurationContextProvider';
import { UserContext } from '../../context/UserContextProvider';
import { useNavigate } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    const clientConfiguration = useContext(ClientConfigurationContext);
    const user = useContext(UserContext);

    const show = {
        profileManagement: clientConfiguration.enableProfileManagement && user.authorizations?.canManageProfileManagement,
        agencyDefaults: clientConfiguration.enableAgencyDefaults && user.authorizations?.canSetAgencyDefaults,
        ivansRequest: clientConfiguration.enableIvansRequest && user.authorizations?.canManageIvansRequests,
        reports: clientConfiguration.enableReports && user.authorizations?.canAccessReports
    };

    const [tabContent] = useState({
        ...(show.profileManagement ? { "Profile Management": <ProfileSearch /> } : {}),
        ...(show.agencyDefaults ? { "Agency Defaults Management": <AgencyDefaultsManagementTabContent /> } : {}),
        ...(show.ivansRequest ? { "Ivans Request": <IvansRequestTabContent /> } : {}),
        ...(show.reports ? { "Reports": <ReportsTabContent /> } : {})
    });

    useEffect(() => {
        if (Object.entries(show).every(([key, value]) => (key === "reports" && !!value) || !value)) {
            navigate("/reports");
        }
    });

    return (
        <>
            <PageHeader
                header="Agency Management System"
                showBottomBorder={false}
            >
                <p>All the tools you'll need to manage Independent Agency profiles, set agency defaults, and more.</p>
            </PageHeader>
            <div className='container margin-top' >
                <Tabs content={tabContent} />
            </div>
        </>
    );
}
