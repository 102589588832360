import { useContext, useState, useEffect } from "react";
import Accordion from "../gdk/Accordion/Accordion";
import { UserContext } from '../../context/UserContextProvider';
import { searchAgencies } from "../../api/search";

export default function AgencyGroup() {

    const user = useContext(UserContext);
    const [agency, setAgency] = useState();
    const [agencyGroup, setAgencyGroup] = useState();

    useEffect(() => {
        if (user?.agencyId) {
            searchAgencies({ agencyId: user.agencyId }, success, error); 
        }
    }, []);
    
    useEffect(() => {
        if (agency?.isMAP) {
            setAgencyGroup(agency);
        }
        else if (agency?.parentAgencyId != null) {
            searchAgencies({ agencyId: agency.parentAgencyId }, success, error); 
        }
    }, [agency])

    const success = (response) => { 
        setAgency(response.find(p => p.agencyId === user.agencyId) ?? {}); 
    };

    const error = () => {};

    return <>
        { !agencyGroup &&
            <div id="alert-low" className="alert">
                <div className="alert--low-importance">
                    <span className="icon-alert"/>
                    <ul className="alert-list">
                        <li className="alert-content">
                            <div className="h4">No Market Access Provider is Onboarded</div>
                            <p>
                                Your agency does not have a Market Access Provider that is onboarded with GEICO
                            </p>
                            {/* 
                                TODO: Uncomment the following line and update link to more information
                                <a href="/alert">More Information</a> 
                            */}
                        </li>
                    </ul>
                </div>
            </div>
        }
        { agencyGroup &&
            <Accordion id="agency-group-accordion">
                <li>
                    <div tabIndex="0" className="accordion-headline">
                        <span className="geico-icon geico-icon--actionable icon-business-ins"></span>
                        <div className="accordion-headline-content-wrapper">
                            <div className="accordion-left-content-wrapper">
                                <div className="heading h4">{agencyGroup?.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-content-container" >
                        <div className="accordion-content container">
                            <ul className="list list--two-column">
                                <li>
                                    <div>Agency Group ID</div>
                                    <div>{agencyGroup?.agencyId}</div>
                                </li>
                                <li>
                                    <div>Business Address</div>
                                    <div>{agencyGroup?.businessAddress?.street1}</div>
                                </li>
                                <li>
                                    <div>Business City or County</div>
                                    <div>{agencyGroup?.businessAddress?.city}</div>
                                </li>
                                <li>
                                    <div>Mailing Address</div>
                                    <div>{AddressString(agencyGroup?.mailingAddress)}</div>
                                </li>
                                <li>
                                    <div>Contact Name</div>
                                    <div>{FullName(agencyGroup?.principal?.name)}</div>
                                </li>
                                <li>
                                    <div>Contact Phone</div>
                                    <div>{ContactPhone(agencyGroup?.principal?.phones)}</div>
                                </li>
                                <li>
                                    <div>Contact Email</div>
                                    <div>{agencyGroup?.principal?.email}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </Accordion>
        }
    </>
}

const AddressString = (a) => a ? `${a.street1}, ${a.city}, ${a.state}, ${a.zipCode}` : "";
const FullName = (n) => n ? `${n.firstName} ${n.lastName}` : "";
const ContactPhone = (p) => p ? `${p.business} ${p?.businessExtension}` : "";