import { handleErrors } from "../util/api-utils";

/**
 * Saves the updated agency profile.
 * @param {any} agencyProfile The updated agency profile.
 * @param {function} successCallback The success callback function.
 * @param {function} finallyCallback The finally callback function.
 */
export function updateAgencyProfile(agencyProfile, successCallback, finallyCallback) {
    fetch("api/AgencyProfile/Update", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(agencyProfile)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error)
        .finally(finallyCallback);
};

/**
 * Gets an agency profile by ID.
 * @param {string} agencyId The ID of the agency to get.
 * @param {function} successCallback The success callback function.
 */
export function getAgencyProfile(agencyId, successCallback) {
    fetch("api/AgencyProfile/Get", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(agencyId)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
    ;
}
