import { useEffect, useState } from "react";

const useLanguageOptions = () => {
    const [languageOptions, setLanguageOptions] = useState();

    useEffect(() => {
        setLanguageOptions([
            { value: "ARA", text: "Arabic" },
            { value: "CHN", text: "Chinese" },
            { value: "ENG", text: "English" },
            { value: "FRE", text: "French (Creole)" },
            { value: "KOR", text: "Korean" },
            { value: "SPA", text: "Spanish" },
            { value: "TAG", text: "Tagalog" },
            { value: "VNM", text: "Vietnamese" },
            { value: "OTHER", text: "Other" }
        ]);
    }, []);

    return languageOptions;
};

export default useLanguageOptions;
